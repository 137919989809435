.k-window-titlebar{
  color: $white;
 }


.custom-dialog {
    .k-dialog {
        height: auto;
        width: auto;
        max-width: 80%;
        max-height: 90%;
        min-width: 10%;
        padding: 1.6667em 1.6667em 2.7778em 2.111em;
        font-size: 1em;
        overflow-y: auto;
        .k-dialog-titlebar {
            border-bottom: 0;
            background: $white;
            padding: 0;
            .k-dialog-title.k-window-title{
                margin: 0;
                font-family: $primary-font;
                color: $black;
                font-size: 1.6667em;
                height: 100%;
                padding: 0.1em 0;
            }
            .k-window-action {
                font-size: 1em;
                z-index: 10000000;
            }
            .k-svg-icon.k-svg-i-x {
                font-size: 1em;
                background: url("../../assets/icons/Cancel_black.svg") no-repeat !important;
                width: 18px;
                height: 18px;
                fill: $black;
                svg {
                  display: none;
                }
            }
        }
        .k-window-content {
            padding-left: 0;
        }
        .k-dialog-content{
            font-size: 1em;
            .k-textbox{
                &:focus{
                    border-color: $black;
                        color: $grey-darkest;
                        background-color: $white;
                }
            }
        }
    }
    span.dialog-icon-delete {
        background: url("/assets/icons/DeleteonToolbar.svg") no-repeat;
        height: 100%;
        width: 28px;
        display: list-item;
    }
    &.custom-dialog-width-medium{
        .k-dialog{
            width:500px;
        }
    }
    &.custom-dialog-width-large{
        .k-dialog{
            width:50%;
        }
    }
    &.custom-dialog-width-xlarge{
        .k-dialog{
            width:65%;
        }
    }
    &.custom-dialog-small{
        .k-dialog{
            min-width: 33%;
        }
    }
    &.custom-dialog-medium{
        .k-dialog{
            min-width: 35%;
        }
    }
    &.custom-dialog-large{
        .k-dialog {
            min-width: 52%;
        }
    }
    &.custom-dialog-confirmation{
        z-index: 20000;
        p.confirmation-text{
            margin: 30px;
            text-align: center;
        }
    }
    &.custom-dialog-overflow-hidden{
        .k-dialog-content {
            overflow: hidden;
        }
    }
    &.custom-dialog-without-close{
        & .k-window-actions{
            display:none;
        }
    }
    &.custom-dialog-file-download{
        .k-dialog {
            padding: 20px 40px;
            .k-dialog-titlebar {
                .k-dialog-title {
                    margin: 0;
                    color: $black;
                    font-size: 0.9em;
                    text-align: center;
                    padding-bottom: 10px;
                }
                .k-dialog-actions {
                    display: none;
                }
            }
            .btn-excel-loading {
                font-size: 0.8889em;
                .k-loading-image {
                    overflow: hidden;
                }
            }
        }
    }
    .dialog-error-message {
        color: $black;
        padding: 20px 10px;
        margin-left: 10px;
    }
    .common-confirmation-modal{
        .k-dialog{
            padding: 0;
            .k-dialog-titlebar{
                padding: 12px 16px;
                height: 45px;
                .k-window-title{
                    color: $white;
                    font-size: 19px;
                    height: auto;
                }
            }
        }
    }

    .common-modal{
        .k-dialog{
            padding: 0;
            .k-dialog-titlebar{
                padding: 12px 16px;
                height: 45px;
                .k-window-title{
                    color: $white;
                    font-size: 19px;
                    height: auto;
                }
            }
        }
    }

 }

 .custom-dialog .k-button-group,
 .k-dialog-actions {
     display: block;
     border-top: 0;
     text-align: right;
     padding: 0.75em 2.22em;
     button {
         padding: 1.38889em 1.9994em;
         font-family: $primary-font;
         font-size: 1em;
     }
     .cdms-btn-blue {
         margin-right: 1.11em;
         background: $secondary-blue !important;
         color: $white !important;
         height: 2.5em;
         width: 9.3333em !important;
         padding: 0.5em 2em;
         &:focus{
            opacity: 0.8;
         }
     }
     .cdms-btn-grey {
         background: $grey !important;
         color: $grey-ultra-darker !important;
         border: 0;
         height: 2.5em;
         width: 9.3333em !important;
         padding: 0.5em 2em;
         &:focus{
            opacity: 0.8;
         }
     }

     .cdms-btn-blue-large {
         width: 11.3333em !important;
     }

     .btn{
         &-icon{
             &-green{
                margin-right: 1.11em;
                background: $primary-green !important;
                color: $white !important;
                height: 2.5em;
                width: 10.3333em !important;
                padding: 0.5em 2em;
             }
             &-grey{
                &-cancel{
                    .k-svg-icon{
                        color:$red;
                      }
                 }
            }

            .k-svg-icon{
                vertical-align: top;
                font-size: 22px;
            }
         }
     }
 }

 .k-dialog-content .custom-grid.k-grid .k-grid-aria-root{
    overflow: auto;
 }
 .grid-emp-lookup,.custom-grid-scroll {
 .k-dialog-content .custom-grid.k-grid .k-grid-aria-root{
    overflow: hidden;
 }

}
