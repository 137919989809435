// FONT SIZES
// =================================
$fs-10: 10px;
$fs-11: 11px;
$fs-12: 12px;
$fs-13: 13px;
$fs-14: 14px;
$fs-15: 15px;
$fs-16: 16px;
$fs-17: 17px;
$fs-18: 18px;
$fs-19: 19px;
$fs-20: 20px;
$fs-21: 21px;
$fs-22: 22px;
$fs-23: 23px;
$fs-24: 24px;
$fs-25: 25px;
$fs-26: 26px;
$fs-27: 27px;
$fs-28: 28px;
$fs-29: 29px;
$fs-30: 30px;
$fs-31: 31px;
$fs-32: 32px;

.fs {
    &-10 {
        font-size: $fs-10 !important;
    }
    &-11 {
        font-size: $fs-11 !important;
    }
    &-12 {
        font-size: $fs-12 !important;
    }
    &-13 {
        font-size: $fs-13 !important;
    }
    &-14 {
        font-size: $fs-14 !important;
    }
    &-15 {
        font-size: $fs-15 !important;
    }
    &-16 {
        font-size: $fs-16 !important;
    }
    &-17 {
        font-size: $fs-17 !important;
    }
    &-18 {
        font-size: $fs-18 !important;
    }
    &-19 {
        font-size: $fs-19 !important;
    }
    &-20 {
        font-size: $fs-20 !important;
    }
    &-21 {
        font-size: $fs-21 !important;
    }
    &-22 {
        font-size: $fs-22 !important;
    }
    &-23 {
        font-size: $fs-23 !important;
    }
    &-24 {
        font-size: $fs-24 !important;
    }
    &-25 {
        font-size: $fs-25 !important;
    }
    &-26 {
        font-size: $fs-26 !important;
    }
    &-27 {
        font-size: $fs-27 !important;
    }
    &-28 {
        font-size: $fs-28 !important;
    }
    &-29 {
        font-size: $fs-29 !important;
    }
    &-30 {
        font-size: $fs-30 !important;
    }
    &-31 {
        font-size: $fs-31 !important;
    }
    &-32 {
        font-size: $fs-32 !important;
    }
    &-1e{
        font-size: 1em !important;
    }
}

// common value
// =================================

$cval: 5px;

// Padding
// =================================
.p {
    &-a {
        &-0 {
            padding: 0px !important;
        }
        &-1p {
            padding: 1px !important;
        }
        &-2p {
            padding: 2px !important;
        }
        &-3p {
            padding: 3px !important;
        }
        &-4p {
            padding: 4px !important;
        }
        &-1 {
            padding: ($cval * 1) !important;
        }
        &-2 {
            padding: ($cval * 2) !important;
        }
        &-3 {
            padding: ($cval * 3) !important;
        }
        &-4 {
            padding: ($cval * 4) !important;
        }
        &-5 {
            padding: ($cval * 5) !important;
        }
        &-6 {
            padding: ($cval * 6) !important;
        }
        &-7 {
            padding: ($cval * 7) !important;
        }
        &-8 {
            padding: ($cval * 8) !important;
        }
        &-9 {
            padding: ($cval * 9) !important;
        }
        &-10 {
            padding: ($cval * 10) !important;
        }
    }
    &-t {
        &-2pe {
            padding-top: 2% !important;
        }
        &-0 {
            padding-top: 0px !important;
        }
        &-1p {
            padding-top: 1px !important;
        }
        &-2p {
            padding-top: 2px !important;
        }
        &-3p {
            padding-top: 3px !important;
        }
        &-4p {
            padding-top: 4px !important;
        }
        &-1 {
            padding-top: ($cval * 1) !important;
        }
        &-2 {
            padding-top: ($cval * 2) !important;
        }
        &-3 {
            padding-top: ($cval * 3) !important;
        }
        &-4 {
            padding-top: ($cval * 4) !important;
        }
        &-5 {
            padding-top: ($cval * 5) !important;
        }
        &-6 {
            padding-top: ($cval * 6) !important;
        }
        &-7 {
            padding-top: ($cval * 7) !important;
        }
        &-8 {
            padding-top: ($cval * 8) !important;
        }
        &-9 {
            padding-top: ($cval * 9) !important;
        }
        &-10 {
            padding-top: ($cval * 10) !important;
        }
    }
    &-r {
        &-0 {
            padding-right: 0px !important;
        }
        &-1p {
            padding-right: 1px !important;
        }
        &-2p {
            padding-right: 2px !important;
        }
        &-3p {
            padding-right: 3px !important;
        }
        &-4p {
            padding-right: 4px !important;
        }
        &-7p {
            padding-right: 7px !important;
        }
        &-30p {
            padding-right: 30px !important;
        }
        &-1 {
            padding-right: ($cval * 1) !important;
        }
        &-2 {
            padding-right: ($cval * 2) !important;
        }
        &-3 {
            padding-right: ($cval * 3) !important;
        }
        &-4 {
            padding-right: ($cval * 4) !important;
        }
        &-5 {
            padding-right: ($cval * 5) !important;
        }
        &-6 {
            padding-right: ($cval * 6) !important;
        }
        &-7 {
            padding-right: ($cval * 7) !important;
        }
        &-8 {
            padding-right: ($cval * 8) !important;
        }
        &-9 {
            padding-right: ($cval * 9) !important;
        }
        &-10 {
            padding-right: ($cval * 10) !important;
        }
        &-3pe {
            padding-right: 3% !important;
        }
    }
    &-b {
        &-0 {
            padding-bottom: 0px !important;
        }
        &-1p {
            padding-bottom: 1px !important;
        }
        &-2p {
            padding-bottom: 2px !important;
        }
        &-3p {
            padding-bottom: 3px !important;
        }
        &-4p {
            padding-bottom: 4px !important;
        }
        &-1 {
            padding-bottom: ($cval * 1) !important;
        }
        &-2 {
            padding-bottom: ($cval * 2) !important;
        }
        &-3 {
            padding-bottom: ($cval * 3) !important;
        }
        &-4 {
            padding-bottom: ($cval * 4) !important;
        }
        &-5 {
            padding-bottom: ($cval * 5) !important;
        }
        &-6 {
            padding-bottom: ($cval * 6) !important;
        }
        &-7 {
            padding-bottom: ($cval * 7) !important;
        }
        &-8 {
            padding-bottom: ($cval * 8) !important;
        }
        &-9 {
            padding-bottom: ($cval * 9) !important;
        }
        &-10 {
            padding-bottom: ($cval * 10) !important;
        }
        &-11 {
            padding-bottom: ($cval * 11) !important;
        }
        &-32p {
            padding-bottom: 32px !important;
        }
        &-80p {
            padding-bottom: 80px !important;
        }
        &-160p {
            padding-bottom: 160px !important;
        }
    }
    &-l {
        &-0 {
            padding-left: 0px !important;
        }
        &-1p {
            padding-left: 1px !important;
        }
        &-2p {
            padding-left: 2px !important;
        }
        &-3p {
            padding-left: 3px !important;
        }
        &-4p {
            padding-left: 4px !important;
        }
        &-7p {
            padding-left: 7px !important;
        }
        &-1 {
            padding-left: ($cval * 1) !important;
        }
        &-2 {
            padding-left: ($cval * 2) !important;
        }
        &-3 {
            padding-left: ($cval * 3) !important;
        }
        &-4 {
            padding-left: ($cval * 4) !important;
        }
        &-5 {
            padding-left: ($cval * 5) !important;
        }
        &-6 {
            padding-left: ($cval * 6) !important;
        }
        &-7 {
            padding-left: ($cval * 7) !important;
        }
        &-8 {
            padding-left: ($cval * 8) !important;
        }
        &-9 {
            padding-left: ($cval * 9) !important;
        }
        &-10 {
            padding-left: ($cval * 10) !important;
        }
        &-1pe {
            padding-left: 1% !important;
        }
        &-2pe {
            padding-left: 2% !important;
        }
        &-4pe {
            padding-left: 4% !important;
        }
        &-13pe {
            padding-left: 13% !important;
        }
        &-6em{
          padding-left: .6em !important;
        }
    }
}

// MARGIN
// =================================
.m {
    &-a {
        &-0 {
            margin: 0px !important;
        }
        &-1p {
            margin: 1px !important;
        }
        &-2p {
            margin: 2px !important;
        }
        &-3p {
            margin: 3px !important;
        }
        &-4p {
            margin: 4px !important;
        }
        &-1 {
            margin: ($cval * 1) !important;
        }
        &-2 {
            margin: ($cval * 2) !important;
        }
        &-3 {
            margin: ($cval * 3) !important;
        }
        &-4 {
            margin: ($cval * 4) !important;
        }
        &-5 {
            margin: ($cval * 5) !important;
        }
        &-6 {
            margin: ($cval * 6) !important;
        }
        &-7 {
            margin: ($cval * 7) !important;
        }
        &-8 {
            margin: ($cval * 8) !important;
        }
        &-9 {
            margin: ($cval * 9) !important;
        }
        &-10 {
            margin: ($cval * 10) !important;
        }
    }
    &-t {
        &-0 {
            margin-top: 0px !important;
        }
        &-1p {
            margin-top: 1px !important;
        }
        &-2p {
            margin-top: 2px !important;
        }
        &-3p {
            margin-top: 3px !important;
        }
        &-4p {
            margin-top: 4px !important;
        }
        &-7p {
            margin-top: 7px !important;
        }
        &-8p {
            margin-top: 8px !important;
        }
        &-10p {
            margin-top: 10px;
        }
        &-12p {
            margin-top: 12px !important;
        }
        &-1 {
            margin-top: ($cval * 1) !important;
        }
        &-2 {
            margin-top: ($cval * 2) !important;
        }
        &-3 {
            margin-top: ($cval * 3) !important;
        }
        &-4 {
            margin-top: ($cval * 4) !important;
        }
        &-5 {
            margin-top: ($cval * 5) !important;
        }
        &-6 {
            margin-top: ($cval * 6) !important;
        }
        &-7 {
            margin-top: ($cval * 7) !important;
        }
        &-8 {
            margin-top: ($cval * 8) !important;
        }
        &-9 {
            margin-top: ($cval * 9) !important;
        }
        &-10 {
            margin-top: ($cval * 10) !important;
        }
        &-23p {
            margin-top: 23px !important;
        }
        &-32p {
            margin-top: 32px !important;
        }
        &-2pm {
            margin-top: -2px !important;
        }
        &-5pm {
            margin-top: -5px !important;
        }
        &-14pm {
            margin-top: -14px !important;
        }
        &-20pm {
            margin-top: -20px !important;
        }
        &-135pm {
            margin-top: -135px !important;
        }
    }
    &-r {
        &-0 {
            margin-right: 0px !important;
        }
        &-1p {
            margin-right: 1px !important;
        }
        &-2p {
            margin-right: 2px !important;
        }
        &-3p {
            margin-right: 3px !important;
        }
        &-4p {
            margin-right: 4px !important;
        }
        &-5p {
            margin-right: 5px !important;
        }
        &-10p {
            margin-right: 10px !important;
        }
        &-20p {
            margin-right: 20px !important;
        }
        &-1 {
            margin-right: ($cval * 1) !important;
        }
        &-2 {
            margin-right: ($cval * 2) !important;
        }
        &-3 {
            margin-right: ($cval * 3) !important;
        }
        &-4 {
            margin-right: ($cval * 4) !important;
        }
        &-5 {
            margin-right: ($cval * 5) !important;
        }
        &-6 {
            margin-right: ($cval * 6) !important;
        }
        &-7 {
            margin-right: ($cval * 7) !important;
        }
        &-8 {
            margin-right: ($cval * 8) !important;
        }
        &-9 {
            margin-right: ($cval * 9) !important;
        }
        &-10 {
            margin-right: ($cval * 10) !important;
        }
        &-a {
            margin-right: auto !important;
        }
    }
    &-b {
        &-0 {
            margin-bottom: 0px !important;
        }
        &-1p {
            margin-bottom: 1px !important;
        }
        &-2p {
            margin-bottom: 2px !important;
        }
        &-3p {
            margin-bottom: 3px !important;
        }
        &-4p {
            margin-bottom: 4px !important;
        }
        &-8p {
            margin-bottom: 8px !important;
        }
        &-5e {
          margin-bottom: .5em !important;
        }
        &-4e {
            margin-bottom: 4em !important;
        }
        &-1 {
            margin-bottom: ($cval * 1) !important;
        }
        &-2 {
            margin-bottom: ($cval * 2) !important;
        }
        &-3 {
            margin-bottom: ($cval * 3) !important;
        }
        &-4 {
            margin-bottom: ($cval * 4) !important;
        }
        &-5 {
            margin-bottom: ($cval * 5) !important;
        }
        &-6 {
            margin-bottom: ($cval * 6) !important;
        }
        &-7 {
            margin-bottom: ($cval * 7) !important;
        }
        &-8 {
            margin-bottom: ($cval * 8) !important;
        }
        &-9 {
            margin-bottom: ($cval * 9) !important;
        }
        &-10 {
            margin-bottom: ($cval * 10) !important;
        }
        &-12 {
            margin-bottom: ($cval * 12) !important;
        }
        &-48p {
            margin-bottom: 48px !important;
        }
    }
    &-l {
        &-0 {
            margin-left: 0px !important;
        }
        &-1p {
            margin-left: 1px !important;
        }
        &-2p {
            margin-left: 2px !important;
        }
        &--2p {
            margin-left: -2px !important;
        }
        &-3p {
            margin-left: 3px !important;
        }
        &-4p {
            margin-left: 4px !important;
        }
        &-1 {
            margin-left: ($cval * 1) !important;
        }
        &-2 {
            margin-left: ($cval * 2) !important;
        }
        &-3 {
            margin-left: ($cval * 3) !important;
        }
        &-4 {
            margin-left: ($cval * 4) !important;
        }
        &-5 {
            margin-left: ($cval * 5) !important;
        }
        &-6 {
            margin-left: ($cval * 6) !important;
        }
        &-7 {
            margin-left: ($cval * 7) !important;
        }
        &-8 {
            margin-left: ($cval * 8) !important;
        }
        &-9 {
            margin-left: ($cval * 9) !important;
        }
        &-10 {
            margin-left: ($cval * 10) !important;
        }
        &-7pm {
            margin-left: -7px !important;
        }
        &-47pm {
            margin-left: -47px !important;
        }
    }
}

// Text Alignments
// =================================
.text {
    &-left {
        text-align: left !important;
    }
    &-right {
        text-align: right !important;
    }
    &-center {
        text-align: center !important;
    }
    &-bold {
        font-weight: bold !important;
    }
    &-capitalize {
        text-transform: capitalize !important;
    }
    &-lowercase {
        text-transform: lowercase !important;
    }
    &-uppercase {
        text-transform: uppercase !important;
    }
    &-area{
        width:18.75em;
        &-16{
            width:16.66em!important
        }
    }
}

// Text Overflow
// =================================
.text-overflow {
  &-clip {
    text-overflow: clip !important;
  }
}

// Float
// =================================
.float {
    &-left {
        float: left !important;
    }
    &-right {
        float: right !important;
    }
    &-none {
        float: none !important;
    }
}

// Display
// =================================
.d {
    &-none {
        display: none !important;
    }
    &-flex {
      display: flex !important;
    }
    &-inline {
        display: inline !important;
    }
    &-block {
        display: block !important;
    }
    &-inline-block {
        display: inline-block !important;
    }
    &-inline-flex {
      display: inline-flex !important;
    }
     &-contents {
    display: contents !important;
}
}

// cursor
// =================================
.cursor {
    &-hand {
        cursor: pointer !important;
    }
}

// cursor
// =================================
.pointer-events{
  &-n{
    pointer-events: none !important;
  }
}

// Overflow
// =================================
.o {
    &-auto {
        overflow: auto !important;
    }
    &-scroll {
        overflow: scroll !important;
    }
    &-hidden {
        overflow: hidden !important;
    }
    &-visible {
        overflow: visible !important;
    }
    &-x {
        &-auto {
            overflow-x: auto !important;
        }
        &-scroll {
            overflow-x: scroll !important;
        }
        &-hidden {
            overflow-x: hidden !important;
        }
        &-visible {
            overflow-x: visible !important;
        }
    }
    &-y {
        &-auto {
            overflow-y: auto !important;
        }
        &-scroll {
            overflow-y: scroll !important;
        }
        &-hidden {
            overflow-y: hidden !important;
        }
        &-visible {
            overflow-y: visible !important;
        }
    }
}
// Border
// =================================
.b {
    &-a{
        border:none!important;
        &-1p{
            border:1px solid $grey-ultra-dark !important;
        }
    }
    &-b {
        &-none {
            border-bottom: none !important;
        }
    }
    &-t{
        &-none {
            border-top: none !important;
        }
        &-1p{
            border-top:1px solid $grey-dark;
        }
    }
    &-r{
        &-none{
            border-right:none!important;
        }
    }
    &-l{
        &-none{
            border-left:none!important;
        }
    }
    &-c{
      border-color: $grey-darker;
    }
}
// Width
// =================================
.w {
    &-min {
      width: min-content !important;
    }
    &-a {
        width: auto !important;
    }
    &-5pe {
        width: 5% !important;
    }
    &-9pe {
        width: 9% !important;
    }
    &-10pe {
        width: 10% !important;
    }
    &-12pe {
        width: 12% !important;
    }
    &-25pe {
      width: 25% !important;
    }
    &-60pe {
      width: 60% !important;
    }
    &-75pe {
      width: 75% !important;
    }
    &-80pe {
      width: 80% !important;
    }
    &-90pe {
        width: 90% !important;
    }
    &-100pe {
        width: 100% !important;
    }
    &-96p {
        width: 96px !important;
    }
    &-100p {
        width: 100px !important;
    }
    &-105p {
      width: 105px !important;
    }
    &-133p {
        width: 133px !important;
    }
    &-150p {
        width: 150px !important;
    }
    &-176p {
        width: 176px !important;
    }
    &-200p {
        width: 200px !important;
    }
    &-266p {
        width: 266px !important;
    }
    &-12p {
        width: 12px !important;
    }
    &-500p{
        width:500px !important
    }
    // Word Break
    &-b{
        &-a{
            word-break:break-word !important;
        }
    }
}
// Min Width
// =================================
.mw{
    &-fc{
        min-width: -moz-fit-content;
        min-width: -webkit-fill-available;
        min-width: fit-content;
    }
}
// vertical Align
// =================================
.v {
    &-a {
        &-t {
            vertical-align: top !important;
        }
        &-m {
            vertical-align: middle !important;
        }
        &-b {
            vertical-align: bottom !important;
        }
        &-tt{
            vertical-align: text-top !important;
        }
        &-tb{
            vertical-align: text-bottom !important;
        }
        &-baseline{
            vertical-align: baseline !important;
        }
    }
}
// Top
// =================================
.t {
    &-05p {
        top: 0.5px !important;
    }
    &-12pem {
        top: -12% !important;
    }
    &-32p{
        top: 32px !important;
    }
    &-065pem{
        top: 0.65em !important;
    }
}
// Color
// =================================
.c {
    &-white {
        color: $white !important;
    }
    &-black {
        color: $black !important;
    }
    &-green{
        color:$primary-green !important;
    }
    &-blue{
        color:$secondary-blue !important;
    }
    &-red{
        color:$red !important;
    }
    &-grey{
        color:$grey !important;
    }
    &-yellow{
      color:$yellow-dark !important;
  }
}
// Height
// =================================
.h {
    &-a {
      height: auto !important;
    }
    &-20p {
        height: 20px !important;
    }
    &-25p {
        height: 25px !important;
    }
    &-64p {
        height: 64px !important;
    }
    &-150p {
        height: 150px !important;
    }
    &-2e {
        height: 2em !important;
    }
    &-45e {
      height: 4.5em !important;
    }
    &-25e {
      height: 2.5em !important;
    }
    &-200p{
        height: 200px !important;
    }
    &-100{
        height: 100% !important;
    }

    &-m{
      &-a {
        min-height: auto !important;
      }
    }
}
// focus
// =================================
.f{
    &-none{
        outline:none!important;
    }
}

// Position
// =================================
.pos{
    &-relative{
        position:relative!important;
    }
    &-absolute{
        position: absolute!important;
    }
    &-fixed{
        position:fixed!important;
    }
}
// flex
// =================================
.flex{
    &-center{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &-start{
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    &-start-align{
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
    }
    &-end{
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    &-end-align{
        display: flex;
        align-items: flex-end;
    }
    &-col{
      display: flex;
      flex-direction: column;
    }
    &-col-end{
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
    &-center-col{
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
    &-between {
        display: flex;
        justify-content: space-between;
    }
    &-center-between {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
}
// Icons
// =================================
.icon{
    &-edit{
        width: 42px;
        height: 17px;
        display: inline-block;
        background:url("../../assets/icons/Edit.svg") no-repeat;
        border: none;
        margin-top: 7px;
    }
    &-key{
        background: url("../../assets/icons/Key.svg") no-repeat;
        width: 1em;
        height: 1em;
        font-size: 18px;
        display: inline-block;
        cursor: pointer;
    }
}
// OPACITY
// =================================
.op{
  &-100{
    opacity: 1 !important;
  }
  &-75{
    opacity: .75 !important;
  }
  &-50{
    opacity: .50 !important;
  }
  &-25{
    opacity: .25 !important;
  }
}
// BACKGROUNDS
// =================================
.bg{
    &-red{
        background-color:$red !important;
    }
    &-green{
        background-color:$primary-green !important;
    }
    &-blue{
      background-color:$secondary-blue !important;
    }
    &-darkblue{
      background-color:$cdcn-blue !important;
    }
    &-yellow{
        background-color: $yellow-dark !important;
    }
    &-white{
      background-color: $white !important;
    }
    &-none{
      background-color: none !important;
    }
    &-inherit{
      background-color: inherit !important;
    }
    &-unset{
        background-color: unset !important;
    }
}
// Generic Message in center of the screen
// =================================
.generic-message-center{
    text-align: center;
    margin-top: 15%;
    width: 100%;
    p{
        font-weight: bold;
        font-size: 16px;
    }
}

//Line-height
.lh{
    &-13p{
        line-height: 13px !important;
    }
}

//Right
.r{
    &-6p{
        right: 6px !important;
    }
}

// White Space
.ws{
    &-nw{
        white-space: nowrap;
    }
}

//text overflow ellipsis
.text-ellipsis {
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
}
