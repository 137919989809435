/*Grid Specific Styles*/
.k-grid{
  tr.passive td {
    background-color: $red-transparent-30p !important;
  }

  &-norecords {
    text-align: left;
  }
  &-header .k-grid-filter {
    padding: 0;
    height: 1em;
    width: 1em;
    top: 1.75em;
    &:hover {
      background: none;
    }
    &.k-active {
      background: none;
      .k-svg-i-filter {
        color: $secondary-blue;
      }
    }
  }
  &-content {
    overflow-y: auto;
  }
  &-header-wrap {
    border-right: 0;
  }
  &-filter-popup { // filter menus need to be able to scroll when there are many items
    max-height: 25em;
    overflow: auto;
  }
}
.k-grid .k-grid-edit-row,
.k-grid .k-grid-add-row {
    & td {
      padding-block: 10px !important;
      & .k-numeric-wrap {
        & input.k-input {
          height: 2.33em;
        }
      }
    }
    .check-editor {
      margin-top: 0px;
      margin-bottom: 10px;
      margin-left: 12px;
      vertical-align: top;
      width: 17px;
      height: 17px;
    }
    .combobox-editor {
      width: 100%;
      height: 2.75em;
      font-size: 0.8889em;
      border: 1px solid $grey-darker;
      box-shadow: 0 2px 6px $grey-dark, 0 2px 3px $transperant-95p;
      .k-searchbar {
        border: 0px;
        .k-input {
          padding: 4px 8px;
          height: 100%;
        }
      }
      .k-dropdown-wrap {
        border: none;
        .k-clear-value {
          display: none;
        }
      }
      &.ng-invalid{
        border-color: $red;
      }
    }
    .multiselect-editor {
      width: 100%;
      font-size: 0.8889em;
      min-height: 2.75em ;
      border: 1px solid $grey-darker;
      box-shadow: 0 2px 6px $grey-dark, 0 2px 3px $transperant-95p;
      .k-multiselect-wrap {
        li.k-button {
          font-size: 1em;
        }
        .k-clear-value {
          display: none;
        }
      }
    }
    .k-datepicker {
      font-size: 1em;
      width: 100%;
      min-height: 2.5em;

          input {
            height: 100% !important;
            border: 0px !important;
            min-height: 2.5em !important;

        &.k-select {
          position: absolute;
          right: 0;
          margin: 0.2em 1em;
          display: inline-block;
          top: 2px;
          width: 1.27778em;
          height: 1.3333em;
          background: $white;
          .k-svg-i-calendar {
            font-size: 16px;
            height: 100%;
            display: inline-block;
            padding-top: 2px;
            margin: 0 ;
            &::before {
              background: url("../../assets/icons/Calendar.svg") no-repeat;
              width: 1.48em;
              height: 1.3333em;
              display: inline-block;
              background-size: 18px;
            }
          }
        }
      }
      .k-dateinput {
        font-size: 1em;
      }
    }
    .k-combobox {
      &-popup {
        width: 100%;
      }
    }
    .k-textbox,
    .k-numerictextbox {
      width: 100% !important;
      height: 2.75em;
      font-size: 0.8889em;
      border: 1px solid $grey-darker;
      box-shadow: 0 2px 6px $grey-dark, 0 2px 3px $transperant-95p;
      &.ng-invalid.ng-touched{
        border-color: $red;
      }
      kendo-searchbar{
        width: 18.75em;
      }
    }
    & .form-ctrl .k-dropdown-wrap {
      & .k-input {
        background-color: $white;
        height: calc(12px + 1.4285714286em);
      }
      & .k-select {
        background-color: $white;
      }
    }
  }
.k-pager-numbers .k-link:hover {
  background-color: $grey-lighter;
}
kendo-grid-boolean-filter-menu{
  & ul{
      & li{
          padding:5px;
          & .k-radio{
              border-color:$grey-darkest;
              &:checked{
                  border-color:$secondary-blue;
                  color:$secondary-blue;
                  background-color:$secondary-blue;
                  background-image: none;
                  &:focus{
                      border-color:$blue-darker;
                      box-shadow: none;
                  }
              }
          }
      }
  }
}
/*Grid Pagination Styles*/
.k-pager-wrap {
  background: $grey-lighter;
  border: none;
  box-sizing: border-box;
  font-family: $primary-font;
  font-size: 1em;
  height: 4em;
  padding: 0.66em;
  .k-pager-nav {
    .k-svg-icon {
      font-size: 1em;
    }
    .k-svg-i-arrow-60-right {
      font-size: 1em;
      height: 1em;
      width: 0.611em;
      &::before {
        background: url("../../assets/icons/DragSome.svg") no-repeat;
        display: inline-block;
      }
      svg {
        display: none;
      }
    }
    .k-svg-i-arrow-end-right {
      font-size: 1em;
      height: 1em;
      width: 1.11em;
      &::before {
        background: url("../../assets/icons/DragAll.svg") no-repeat;
        display: inline-block;
      }
      svg {
        display: none;
      }
    }
    .k-svg-i-arrow-60-left {
      width: 0.611em;
      height: 1em;
      font-size: 1em;
      &::before {
        background: url("../../assets/icons/DragBacksome.svg") no-repeat;
        display: inline-block;
      }
      svg {
        display: none;
      }
    }
    .k-svg-i-arrow-end-left {
      width: 1.11em;
      height: 1em;
      font-size: 1em;
      &::before {
        background: url("../../assets/icons/Dragallback.svg") no-repeat;
        display: inline-block;
      }
      svg {
        display: none;
      }
    }
    &:hover {
      background: $transparent;
    }
  }
  a.k-link {
    border: none;
    min-width: auto;
    padding: 0.4em 0.833em;
  }
  .k-pager-info {
    color: $black;
    display: block;
  }
.k-pager-numbers {
    button {
      color: $grey-darker !important;
      &::before{
        background: none !important;
      }
      &k:hover {
        color: $black !important;
        font-weight: 400 !important;
      }
      &.k-selected:hover {
        background-color: $grey-lighter !important;
        color: $black !important;
        border-style: none !important;
        border: none !important;
        font-weight: 500 !important;
      }
      &.k-selected {
        background-color: $grey-lighter !important;
        color: $black !important;
        border-style: none !important;
        border: none !important;
        font-weight: 500 !important;
      }
    }
  }
}
.k-pager {
  background: $grey-lighter;
}
/*End of Grid Pagination Styles*/

.custom-grid {
  font-size: 1em;
  margin-bottom: 5em;
  &.k-grid {
    border: none;
    table {
      border: none;
      font-size: 1em;
      font-family: $primary-font;
      .k-checkbox:indeterminate + .k-checkbox-label::after {
        background: $white;
        color: $white;
        border-color: $grey-darker;
      }
      th {
        .k-checkbox {
          width: 14px;
          height: 14px;
          border-color: $grey-darker;
        }
        .k-checkbox:checked {
          background-color: $white;
          content: "\e118";
          color: $grey-ultra-dark;
          border-color: $grey-darker;
        }
        .k-checkbox-label {
          margin-top: 0.16667em;
        }
      }
      label.k-checkbox-label {
        float: left;
        &::before {
          border-color: $grey-ultra-dark;
          background-color: $white;
          width: 12px;
          height: 12px;
          font-size: 1em;
        }
        &::after {
          color: $grey-ultra-dark;
          top: 1px;
          left: 1px;
          width: 12px;
          height: 12px;
          border-color: $grey-ultra-dark;
        }
      }
      tr {
        &.k-alt {
          background: $white;
          &:hover {
            background: $grey-lightest !important;
          }
        }
        &:hover {
          background: $grey-ultra-light;
        }
        &.k-selected > td {
          background: $grey-light;
          background-color: $grey-light !important;
        }
      }
      th.k-header {
        white-space: normal;
        background: $grey-lighter;
        height: 4em;
        box-sizing: border-box;
        padding: 1.11em;
        border-right: 2px solid $grey-darker;
        border-left: 0;
        color: $grey-ultra-dark;
        border-bottom: 0;
        font-size: 0.889em;
        text-overflow: clip;
        &:last-child {
          border-right: 0;
        }
        .k-cell-inner {
          a.k-grid-filter-menu.k-grid-header-menu {
              position: static;
              margin-inline-end: 12px !important;
              background: none;
              &.k-active{
                .k-svg-i-filter {
                  color: $secondary-blue;
                }
              }
          }

          .k-svg-i-sort-asc-small{
            color: $black;
          }
      }
      }
      td {
        border: none;
        color: $grey-ultra-darker;
        padding: 1.11em;
        font-size: 1em;
        word-break: break-word;
        word-wrap: break-word;
        text-overflow: clip;
        a {
          color: $secondary-blue;
          &:hover {
            color: $secondary-blue;
          }
        }
        & .k-textbox[disabled="true"] {
          background-color: $grey-lighter;
        }
        & .look-over-btn {
          background: url(/assets/icons/Lookup.svg) no-repeat !important;
          width: 30px;
          height: 25px;
          svg{
            display: none !important;
          }
        }
        & .look-over-btn-inline {
          background: url(/assets/icons/Lookup.svg) no-repeat !important;
          width: 30px;
          height: 25px;
          position: absolute;
          transform: translate3d(-36px, 7px, 0px);
          svg{
            display: none !important;
          }
        }
        autocomplete-box{
          div {
            white-space: nowrap;
          }
        }
        kendo-dropdownlist{
          height: 2.778em !important;
        }
      }
    }
    .k-grid-toolbar {
      padding: 0 !important;
      display: block;
      .action-head .flex-end{
        width:auto;
      }
    }
  }
  &.custom-grid-inline-edit-bg{
    &.k-grid table tr{
      &.k-selected > td{
        background-color: $white;
      }
      &.k-grid-edit-row{
        > td{
          background: $grey-ultra-light;
        }
      }
      &:hover{
        > td{
          background: $grey-ultra-light;
        }
      }
    }
  }
  &.custom-grid-mb-auto {
    margin-bottom: auto;
  }
  &.custom-grid-hierarchy{
    th {
      &.k-hierarchy-cell {
        border-right: 0;
      }
    }
    td.k-hierarchy-cell {
      .k-plus {
        background: url("../../assets/icons/Expand.svg") no-repeat;
        width: 10px;
        height: 15px;
        svg {
          display: none;
        }
      }
      .k-minus {
        background: url("../../assets/icons/CollapseBlack.svg") no-repeat !important;
        width: 15px;
        height: 10px;
        svg{
          display: none !important;
        }
      }
    }
    .k-detail-row {
      height: 200px;
      td {
        &.k-hierarchy-cell {
          display: none;
        }
        &.k-detail-cell {
          padding: 0;
          width: 100%;
          position: absolute;
          max-height: 200px;
          overflow-y: auto;
          background: $blue-ultra-light;
          .k-grid-header {
            background: $grey-ultra-dark;
            .k-grid-header-wrap {
              border-right: 0;
              table {
                th {
                  background: $grey-ultra-dark;
                  color: $white;
                }
              }
            }
          }
          table.k-grid-table {
            tr {
              background: $blue-ultra-light;
            }
          }
        }
      }
    }
  }
  &.custom-grid-selectable{
    .k-grid-header {
      background: $grey-ultra-dark;
      border: 0;
    }
    td {
      input[type="checkbox"] {
        width: 14px;
        height: 14px;
        margin-top: 5px;
        border-color: $grey-darker;
        text-overflow: clip;
      }
    }
  }
  &.custom-grid-header-noscroll {
    .k-grid-header {
        padding: 0 !important;
    }
    .k-grid-footer {
      padding: 0 !important;
    }
  }
  &.custom-grid-vertical-scroll {
    .k-grid-header {
      padding-right: 17px !important;
    }
  }
  &.custom-grid-va-middle{
    .k-grid-edit-row {
      td {
        vertical-align: middle;
      }
    }
  }
  &.custom-grid-inline-editable{
    max-height: 450px;
    height: auto;
    .k-grid-toolbar {
      background-color: $blue-dark;
      min-height: 50px;
      .k-grid-add-command {
        float: right;
        margin-right: 10px;
        margin-right: 5px;
        background: $secondary-blue;
        color: $white ;
        background: none ;
        border: none;
      }
      .grid-add-cancel-command {
        float: right;
        margin-right: 10px;
        margin-right: 5px;
        color: $white ;
        background: none ;
        border: none ;
      }
    }
    &-xl{
      max-height: 850px;
    }
    .k-grid-edit-row{
      & .allocatedHours{
          & span{
              color: $red;
              &.inline-error{
                  display: inline-block;
              }
          }
      }
      & input.allocatedHours{
          border:1px solid $red;
      }
    }
  }
  &.custom-grid-wrapper {
    overflow: auto;
    display: flex;
    flex-direction: column;
    a {
      i.look-over {
        background: url("/assets/icons/Lookup.svg") no-repeat;
        float: left;
        height: 1.22em;
        width: 1.5em;
        filter: invert(-5);
      }
    }
  }
  &.custom-grid-blue-header{
    & kendo-grid-toolbar {
      background-color: $blue-dark;
      color: $white;
    }
  }
  &.custom-grid-filter-menu{
    &.k-grid {
      table {
        th.k-header {
          &:last-child {
            kendo-grid-filter-menu {
              a.k-grid-filter {
                span.k-svg-i-filter {
                  margin-right: 2.3em;
                }
              }
            }
          }
        }
      }
    }
  }
  &.custom-grid-darkgrey-bg{
    th {
      background: $grey-ultra-dark;
      color: $white;
    }
  }
  &.custom-grid-hide-column-filter{
    .k-filtercell-operator {
      margin-left: 4px;
      display: none;
    }
  }
  &.custom-grid-nobg{
    .k-grid-header {
      background: none;
      padding: 0;
    }
  }
  .refresh-adjustment{
    &-row{
      &.k-state-selected {
        .refresh-adjustment-col {
          color: $red;
          font-weight: bold;
        }
        td {
          background: $blue-ultra-lighter;
        }
      }
      &-remove{
        &.k-state-selected {
          .refresh-adjustment-col {
            color: $red;
            font-weight: bold;
          }
          td {
            background: $yellow-light;
          }
        }
      }
    }
    &-col {
      color: $red;
      font-weight: bold;
    }
  }
  &.custom-grid-footer{
    .k-pager-sizes > select{
      width: 3.5em;
    }
  }
  &.custom-grid-validation{
    .combobox-editor,
    .k-datepicker,
    .multiselect-editor{
      &.ng-invalid{
        border-color: $red;
      }

      }
    }
}

.custom-grid-darkgrey-bg {
  .k-grid-container {
    a.anchor-blue {
      color: $secondary-blue;
    }
  }
}

/*Grid Toolbar Styles*/
.grid{
  /* Grid header/body custom icons */
  &-icon{
    &-wrapper{
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      &-left{
        width: 50%;
        justify-content: flex-start;
      }
      &-right{
        width: 50%;
        justify-content: flex-end;
      }
    }
  }
  /*End of Grid header/body custom icons */
  &-toolbar-heading{
    font-size: 1.1em;
    margin: 0em;
    padding-left: 0.6em;
    color: $white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: auto;
    height: 100%;
    h6{
      margin-bottom: 0;
    }
  }
  &-toolbar-fixed{
    .k-grid-toolbar {
      padding: 0 1em;
      height: 50px;

      .grid-add-cancel-command,
      .k-grid-add-command{
        padding: 0;

        i.cancel-icon{
          background: url("../../assets/icons/Cancel.svg") no-repeat 5px;
          background-color: $white;
          width: 20px;
          height: 20px;
          background-size: 0.75em;
        }

        .k-svg-i-check-circle{
          font-weight: bold;
        }

        .button-text{
          padding: 3px 0;
          line-height: 14px;
        }
      }
    }
  }
  &-datepicker.k-datepicker {
      & .k-input {
        box-shadow: none;
      }
      & .k-select {
        margin-right: 0px;
      }
  }
  &-action-wrap.input-options ul.k-reset {
    top: 2.3em;
    width: 16.5em;
  }
  &-lookup {
    & div {
      position: relative;
      & .k-textbox {
        padding-right: 35px;
        font-size: 1em;
        width: 14.5em;
        height: 2.8em;
      }
      & .look-over-btn {
        position: absolute;
        background: url(/assets/icons/Lookup.svg) no-repeat !important;
        min-width: 0px !important;
        width: 30px;
        height: 30px;
        right: 0.5556em;
        top: 0.6em;
        z-index: 1;
        svg{
          display: none !important;
        }
      }
    }
    &-fullwidth {
      & div {
        & .k-textbox {
          width: 100%;
        }
      }
    }
    svg {
      display: none !important;
    }
  }
  &-help-block {
    display: inline-block;
    height: 20px;
    color: $red;
    margin-top: 5px;
    word-break: break-word;
    &-status {
      display: inline-block;
      height: 17px;
      color: $red;
      margin-top: 5px;
      word-break: break-word;
    }
  }
  &-multiselect{
    width: 100%;
    height: 6em;
    overflow-y: auto;
    .multi-select-wrap{
      width: 100%;

      ul.k-reset {
        top: 2.3em;
        width: 100%;
        background: $white;
        border: 1px solid $grey-dark;
        box-shadow: 0 2px 6px $grey-dark, 0 2px 3px $black;
        position: absolute;
        z-index: 1;

        li.k-button {
          background: $secondary-blue;
          height: 2.75em;
          color: $white;
          box-sizing: border-box;
          border-radius: 2.778em;
          font-size: .667em;
          margin-right: 0.5em;
        }
      }

      .k-button .k-select {
        padding-bottom: 7px;

        span.k-svg-i-close{
          font-size: 0.8889em;
        }
      }
    }
  }
}
.action-head {
  .col {
    display: flex;
  }

  .k-textbox,
  .k-combobox,
  .k-numerictextbox,
  .k-dropdownlist,
  .k-datepicker {
    box-shadow: none !important;
    border: none !important;
  }

  & .k-textbox-container {
    margin-right: 10px;
    height: auto !important;

    & input {
      font-size: 0.8889em;
      height: 2.75em;
      width: 16em;
    }
  }

  & a {
    @extend .cdms-action-head-btn;
    min-width: 50px;
  }

  & a:hover,
  & button:hover {
    opacity: 0.5;
  }
  kendo-svgicon {
    width: 18px !important;
    height: 18px !important;
  }
  .cdms-action-head-btn-refresh{
    background: none;
    border: none;
    width: auto;
    margin: 0;
    padding: 0;
    min-height: 24px;
    min-width: 7.5em;
    .k-svg-i-refresh {
      font-size: 20px !important;
      &::before {
        color: $white;
      }
    }
  }
  &.action-head-fixed{
    height: 50px;
    min-height: 50px;
    padding: 0.5em 1em;

    &.action-head-right-data {
      span{
        padding-top: 7px;
        padding-right: 0;
      }
    }

    .buttons-wrapper{
      padding: 5.25px 0;
      height: 100%;
    }
    a.cdms-action-head-btn-create,
    a.cdms-action-head-btn-copy,
    a.cdms-action-head-btn-delete{
      vertical-align: middle;
       span{
        padding: 3px 0;
        margin-left: 5px;
        line-height: 14px;
      }
      kendo-svgicon{
        color: $white;
      }
    }

    a.cdms-action-head-btn-create{
      width: 20px;
      height: 20px;
      kendo-svgicon{
        color: $white;
      }
    }

    a.cdms-action-head-btn-export{
      i.k-svg-i-file-excel{
        margin: -3px -2px 0px;
        font-size: 20px;
      }
       span{
        vertical-align: text-top;
        padding: 3px 0;
        margin-left: 5px;
        line-height: 14px;
      }
    }
  }
  &-right-data {
    width: 99.9%;
    text-align: right;
    span {
      width: 100%;
      padding-top: 5px;
      padding-right: 5px;
    }
  }
  & .flex-start {
    & span.action-header-text {
      padding-right: 10px;
    }
    & .lcn-status-header{
      width: 18em;
    }
    & div.k-textbox-container {
      height: 2.5em;
    }
    & button.cdms-btn-blue {
      height: 2.5em;
    }
  }
  .col-8,
  .col {
    font-size: 1em;
    padding: 0;
    label {
      margin-bottom: 0;
    }
  }
  a, button.action-head-button {
    display: inline-block;
    background: transparent;
    border: none;
    i {
      font-size: 20px;
      &.k-svg-i-file-excel {
        font-size: 20px;
      }
    }
    span {
      display: inline-block;
      padding: 0 0.4em;
      font-size: 1em;
      color: $white;
    }
  }
  .pt-0.k-textbox-container {
    height: 2.5em;
    input.k-rounded-md{
      font-size: 0.8889em;
      border-radius: 0px;
      width: 16em;
    }
  }
  .pt-1.k-textbox-container {
    height: 2.5em;
    margin: 0 1em 0 0.5em;
    width: 6.4em;
    input {
      font-size: 0.7778em;
    }
  }
  .k-list-container {
    height: 2.5em;
    margin: 0 1em 0 0.5em;
  }
  .k-datepicker {
    margin: 0 1em 0 0.5556em;
    width: 10em;
    border: none;
    .k-select {
      background: none;
    }
    .k-dateinput {
      input {
        height: 100%;
      }
    }
    .k-svg-i-calendar {
      font-size: 16px;
      width: 2em;
      float: right;
      height: 100%;
      padding: 7px 0 9px 7px;
      &::before {
        background: url("../../assets/icons/Calendar.svg") no-repeat;
        width: 1.48em;
        height: 1.11em;
        display: inline-block;
        background-size: 18px;
      }
    }
  }
  .k-combobox {
    left: 0.6em;
    width: 15em;
    height: 2.75em;
    .k-dropdown-wrap {
      span.k-select {
        position: absolute;
        right: 15px;
        top: 15px;
        background: url("../../assets/icons/CollapseBlack.svg") no-repeat !important;
        width: 15px;
        height: 10px;
        margin-right: 0;
        .k-svg-i-arrow-s {
          svg {
            display: none;
          }
        }

        svg{
          display: none !important;
        }
      }
    }
    .k-numeric-wrap,
    .k-dropdown-wrap {
      border: none;
      input {
        width: 14.667em;
        height: 100%;
        font-size: 0.8889em;
        border: 1px solid $grey-darker;
        box-shadow: 0 2px 6px $grey-dark, 0 2px 3px $transperant-95p;
        &:disabled {
          background: $grey-lighter;
        }
      }
      span.k-select.ng-star-inserted {
        display: none;
      }
    }
  }
  header-combo-lg{
    width:14em!important;
    & input.k-input{
        width:100%!important;
    }
  }
  .cdms-btn-refresh {
    background: none;
    box-shadow: none;
    border: none;
    margin: 0 0.2em;
    width: auto;
    .k-svg-i-refresh {
      font-size: 1.5em;
      &::before {
        color: $white;
      }
    }
  }
  .k-grid-add-command {
    background: none;
    border: none;
    padding-right: 0;
  }
  .k-ctrl{
    & autocomplete-box{
        position: relative;
        & kendo-combobox.k-combobox{
            left:0em!important;
            width:18.75em !important;
            height:2.75em !important;
        }
        & a.look-over-btn{
            position: absolute;
            background: url(/assets/icons/Lookup.svg) no-repeat !important;
            width: 25px;
            height: 20px;
            right: -135px;
            top: 30px;
        }
        svg{
          display: none !important;
        }
    }
  }
}
.checkbox-disable {
  .k-checkbox-label {
    pointer-events: none;
  }
  .k-checkbox {
    pointer-events: none;
  }
  .k-checkbox-label::before {
    background: $grey-lighter;
  }
}
/*End of Grid Toolbar Styles*/
/*End of Grid Specific Styles*/

/*Grid Styles overrides - Increased Specificity Based on other Parent Controls*/
.custom-dialog .custom-grid{
  margin-bottom:0;
  &-with-margin{
    margin-bottom: 10px;
  }
}
.budget-summary-wrap {
  .custom-grid.k-grid table {
      th.k-header {
          background: $grey-ultra-dark;
          color: $white;
      }
  }
}

.k-checkbox{
  border-radius: 0px;
  border-color: $grey-darker;
}
.customer-search {
  .custom-grid.k-grid .k-grid-toolbar {
  display: none !important;
  }
}

.qualification-type-grid,
.notification-messages-grid{
  .custom-grid.k-grid .k-grid-toolbar{
    display: none;
  }
  white-space: break-spaces;
}

.notification-messages-toolbar{
  .action-head-fixed-height {
  div.search-toolbar{
    width: 31em;
    padding-top: 0.1em;
  }
  div.k-button-toolbar{
    margin-top: -3em;
  }
}
}

.custom-grid > .k-grid-aria-root > .k-grid-container > .k-grid-table-wrap > .k-grid-table {
  .k-touch-action-auto {
    .k-maskedtextbox{
      border: none;
    }
  }
}


.custom-grid.common-grid.k-grid .k-grid-aria-root {
  overflow-x: hidden;
  overflow-y: auto;
 }

 col.k-sorted, th.k-sorted{
    background-color: inherit;
  }

kendo-grid {
  max-height: 70vh;
  overflow: auto;
}

.k-grid-header {
  position: sticky;
  top: 0;
  z-index: 2;
}

.k-svg-i-none{
  display: none;
  z-index: 1;
}

.k-svg-i-filter {
  width: 16px !important;
  height: 16px !important;
}
/*End of Grid Styles overrides*/
