@media only screen and (min-width: 740px) and (max-width: 1380px) {
    body {
        font-size: 11px !important;
    }
    .cdms-btn-grey-dark {
        top: 70px !important;
    }
    .action-head {
        .action-item-wrap {
            margin-top: 2em !important;
        }
        .cdms-btn-refresh .k-svg-i-refresh {
            margin: 0;
        }
        & > label span {
            display: inline-block;
            margin: 0 1em 0.5em 0.5em;
            &.add-service-text {
                display: inline-block;
                margin-top: 0.5em;
            }
        }
    }
    .action-header-text {
        display: block;
        margin: 0 1em 0.5em 0.5em;
    }

    .form-wrap-fixed-width {
        .k-numerictextbox,
        .k-combobox {
            width: 14.667em;
            .k-dropdown-wrap {
                span.k-select {
                    position: absolute;
                    right: 28px;
                    top: 15px;
                    background: url("../../assets/icons/CollapseBlack.svg") no-repeat !important;
                    width: 15px;
                    height: 10px;
                    margin-right: 0 !important;
                    .k-svg-i-arrow-s {
                      svg {
                        display: none;
                      }
                    }

                    svg{
                      display: none !important;
                    }
                }
            }
            .k-numeric-wrap,
            .k-dropdown-wrap {
                border: none;
                input {
                    width: 14.667em;
                    height: 2.25em;
                    font-size: 0.8889em;
                    border: 1px solid $grey-darker;
                    box-shadow: 0 2px 6px $grey-dark, 0 2px 3px $transperant-95p;
                    &:disabled {
                        background: $grey-lighter;
                    }
                }
                span.k-select.ng-star-inserted {
                    display: none;
                }
            }
            .k-numeric-wrap {
                span.k-select {
                    display: none;
                }
            }
        }
        .k-select {
            margin: 0 1em !important;
            .k-svg-i-calendar {
                margin: 0 !important;
            }
        }

        .k-dateinput {
            input {
                height: 2.75em !important;
                box-shadow: 0 2px 6px $grey-dark, 0 2px 3px $transperant-95p;
            }
        }
    }

    .form-wrap {
        .k-numerictextbox {
            width: 13.07em;
            .k-dropdown-wrap {
                span.k-select {
                    position: absolute;
                    right: 6px;
                    top: 15px;
                    background: url("../../assets/icons/CollapseBlack.svg") no-repeat !important;
                    width: 15px;
                    height: 10px;
                    margin-right: 0 !important;
                    .k-svg-i-arrow-s {
                      svg {
                        display: none;
                      }
                    }

                    svg{
                      display: none !important;
                    }
                }
            }
            .k-numeric-wrap,
            .k-dropdown-wrap {
                border: none;
                input {
                    width: 18.75em;
                    height: 2.25em;
                    font-size: 0.8889em;
                    border: 1px solid $grey-darker;
                    box-shadow: 0 2px 6px $grey-dark, 0 2px 3px $transperant-95p;
                    &:disabled {
                        background: $grey-lighter;
                    }
                }
                span.k-select.ng-star-inserted {
                    display: none;
                }
            }
            .k-numeric-wrap {
                span.k-select {
                    display: none;
                }
            }
        }
        .k-form-field {
            .k-datepicker {
                font-size: 1em;
                width: 16.6667em;
                span.k-select {
                    position: absolute;
                    right: 0;
                    margin: 0.2em 1em;
                    display: inline-block;
                    top: 1px;
                    width: 1.27778em;
                    height: 1.3333em;
                    background: $white;
                    .k-svg-i-calendar {
                        font-size: 16px;
                        height: 100%;
                        display: inline-block;
                        padding-top: 2px;
                        margin: 0 !important;
                        &::before {
                            background: url("../../assets/icons/Calendar.svg") no-repeat;
                            width: 1.48em;
                            height: 1.3333em;
                            display: inline-block;
                            background-size: 1em;
                        }
                    }
                }
                .k-dateinput {
                    font-size: 1em;
                }
            }
            .k-dateinput {
                input {
                    width: 18.75em;
                    height: 2.75em;
                    font-size: 0.8889em;
                    border: 1px solid $grey-darker;
                    box-shadow: 0 2px 6px $grey-dark, 0 2px 3px $transperant-95p;
                    &:disabled {
                        background: $grey-lighter;
                    }
                }
            }
            span[role="listbox"] {
                height: auto !important;
            }
            kendo-dropdownlist{
                font-size: 0.8889em !important;
                height: 2.75em !important;
            }

            .cust-timepicker.w-90pe{
                .k-dateinput input{
                    width: 100%;
                }
            }
        }
        .k-disabled {
            .k-select {
                background: $grey-lighter !important;
            }
        }
        .k-textbox {
            &:disabled {
                background: $grey-lighter;
            }
        }
        .custom-time-control {
            .k-dateinput {
                input {
                    width: 100% !important;
                    height: 2.1em !important;
                    font-size: 0.8889em;
                    border: 1px solid $grey-darker;
                    box-shadow: 0 2px 6px $grey-dark, 0 2px 3px $transperant-95p;
                    &:disabled {
                        background: $grey-lighter;
                    }
                }
            }
        }

        .look-over-btn {
          position: absolute;
          background: url('/assets/icons/Lookup.svg') no-repeat !important;
          width: 25px !important;
          height: 25px;
          right: 7px;
          top: 22px !important;
        }

        .combobox-wrap-sm {
          .k-clear-value {
            .k-svg-icon.k-svg-i-x {
              margin-left: -3.75em;
              margin-top: -3px;
            }
          }
        }

        .combobox-wrap-sm.k-combobox button.k-input-button {
          position: absolute;
          right: 5px;
          top: 10px !important;
          background: url('../../assets/icons/CollapseBlack.svg') no-repeat !important;
          width: 15px;
          height: 10px;
          margin-right: 0 !important;

          .k-svg-i-caret-alt-down{
            svg{
              display: none !important;
            }
          }
        }
    }

    .form-wrap-timepicker {
        width: 6.33em !important;
        .k-dropdown-wrap {
            span.k-select {
                position: absolute;
                right: 20px !important;
                top: 15px;
                background: url("../../assets/icons/CollapseBlack.svg") no-repeat !important;
                width: 15px;
                height: 10px;
                margin-right: 0 !important;
                .k-svg-i-arrow-s {
                  svg {
                    display: none;
                  }
                }

                svg{
                  display: none !important;
                }
            }
        }
        .k-numeric-wrap,
        .k-dropdown-wrap {
            border: none;
            input {
                width: 6.33em !important;
                height: 2.75em;
                font-size: 0.8889em;
                border: 1px solid $grey-darker;
                box-shadow: 0 2px 6px $grey-dark, 0 2px 3px $transperant-95p;
                &:disabled {
                    background: $grey-lighter;
                }
            }
            span.k-select.ng-star-inserted {
                display: none;
            }
        }
        .k-numeric-wrap {
            span.k-select {
                display: none;
            }
        }
    }

    .custom-combobox-sm {
        width: 6.667em !important;
        .k-dropdown-wrap {
            span.k-select {
                position: absolute;
                right: 10px !important;
                top: 15px;
                background: url("../../assets/icons/CollapseBlack.svg") no-repeat !important;
                width: 15px;
                height: 10px;
                margin-right: 0 !important;
                .k-svg-i-arrow-s {
                  svg {
                    display: none;
                  }
                }

                svg{
                  display: none !important;
                }
            }
        }
        .k-numeric-wrap,
        .k-dropdown-wrap {
            background: $white;
            border: 1px solid $grey-darker;
            height: 2.5em;
            box-shadow: 0 2px 6px $grey-dark, 0 2px 3px $transperant-95p;
            input {
                width: 8.667em !important;
                height: 2.75em;
                font-size: 0.8889em;
                border: 1px solid $grey-darker;
                box-shadow: 0 2px 6px $grey-dark, 0 2px 3px $transperant-95p;
                &:disabled {
                    background: $grey-lighter;
                }
            }
            span.k-select.ng-star-inserted {
                display: none;
            }
        }
        .k-numeric-wrap {
            span.k-select {
                display: none;
            }
        }
    }

    .custom-dialog.k-dialog-wrapper{
        top: -70px !important;
    }

}
@media (min-width: 150px) and (min-width: 210px) {
    .config-width {
        width: 200px !important;
    }
}
@media (min-width: 320px) and (min-width: 576px) {
    .config-width {
        width: 189px !important;
    }
}
@media (min-width: 768px) and (min-width: 992px) {
    .config-width {
        width: 185px !important;
    }
}
@media (min-width: 1400px) {
    .config-width {
        width: 230px !important;
    }
}
@media screen and (max-width: 1920px) {
    .config-height {
        margin-bottom: 15px !important;
    }
}
