@font-face {
  font-family: 'Segoe UI', 'Segoe UI SemiBold';
  src: url('../../fonts/SEGOEUI.TTF');
}
@font-face {
  font-family: 'Segoe UI SemiBold';
  src: url('../../fonts/seguisb.ttf');
}
body {
  font-family: Segoe UI;
  font-size: 14px;
  overflow-y: hidden;
}
.container {
  max-width: 100vw;
  padding: 0;
  width: 100%;
  .content {
    width: 100%;
    height: calc(100vh - 5em);
    overflow: auto;
    .breadcrumb-wrap {
      margin: 1.6667em 1.667em 0;
      padding-left: 0em;
      padding-bottom: 0.5em;
      border-bottom: 1px solid $grey-darker;
      a {
        font-size: 1.3333em;
        color: $secondary-blue;
        &:last-child {
          color: $black;
          cursor: default;
        }
      }
    }
    h1.page-title {
      font-size: 1.6667em;
      font-family: $primary-font;
      border-bottom: 1px solid $grey-darker;
      margin: 0 1em;
      padding: 0 0 0.33em 0.5em;
      display: none;
    }
    .page-content {
      margin: 1.9444em 1.6667em;
      background: $blue-darker;
      min-height: calc(100vh - 14em);
      &.roles-content {
        background: $white;
      }
      .action-head {
        background: $blue-dark;
        height: auto;
        color: $white;
        padding: 1.5em;
        text-align: right;
        &.grid-spacing {
          padding: 0.5em;
        }
        &-sm {
          padding: 0 0.5em;
        }
        &-fixed-height {
          height: 3.6em;
          width: 100%;
          margin-left: 1px !important;
        }
        .grid-add-cancel-command {
          background: none;
          border: none;
          outline: none;
          color: $white;
        }
      }
      .auth-summary-wrap .action-head {
        height: 4.7em;
      }
      .action-head-utilization {
        background: $blue-dark;
        height: auto;
        color: $white;
        text-align: left;
      }
    }
  }
  .user-role-mapping-search {
    display: block;
    border: 1px solid $grey-dark;
    width: 19em;
    font-size: 1em;
    height: 2.8889em;
    padding-right: 2.8em;
    margin-bottom: 1.6667em;
    box-shadow:
      0 2px 6px $grey-dark,
      0 2px 3px $transperant-95p;
    .k-svg-icon.k-clear-value.k-svg-i-close {
        display: none !important;
    }
    .k-searchbar{
      height: 100%;
    }
  }
  a.search {
    position: absolute;
    top: 25px;
    padding: 0.5em;
    width: 2.5em;
    height: 2.5em;
    right: 0;
    font-size: 1em;
    .search-icon {
      background: url('../../assets/icons/Search.svg') no-repeat;
      width: 1em;
      height: 1em;
      font-size: 18px;
      display: inline-block;
    }
  }
}
.toast {
  &-container {
    background-color: $transparent !important;
  }
  &-top-right {
    top: 80px;
    box-shadow: none;
    right: 0;
    .ngx-toastr {
      padding: 2em 4em !important;
      border-radius: 0 !important;
      width: auto !important;
      box-shadow: none !important;
      &.toast-success {
        background-color: $green;
      }
    }
  }
}
.create-auth-budget-summary,
.copy-auth-budget-summary {
  .action-head {
    display: block !important;
  }
}
.all-companies-wrap {
  display: inline-block;
  margin-left: 15px;
  label.k-checkbox-label.k-no-text {
    padding: 0;
    margin: 0;
    background: $white;
    color: $grey-ultra-dark;
    margin-top: 2px;
    margin-left: 5px !important;
    &::after {
      color: $grey-ultra-dark;
      top: 1px;
      left: 1px;
      width: 1.11em !important;
      height: 1.11em !important;
    }
    &::before {
      border-color: $grey-ultra-dark;
    }
  }
  .k-checkbox,
  .k-checkbox:checked,
  .k-checkbox:focus,
  .k-checkbox-label::before {
    border-color: $grey-ultra-dark;
    background-color: $white;
    color: $grey-ultra-dark;
    width: 1.11em !important;
    height: 1.11em !important;
  }
}
.footer-success {
  padding-right: 15em;
  font-weight: bold;
  color: $green;
  font-size: x-large;
}
.footer-failure {
  padding-right: 15em;
  font-weight: bold;
  color: $red;
  font-size: x-large;
}
.budget-summary-wrap {
  width: calc(100vw - 24em);
  overflow-x: none;
}
.page-loading-bg {
  background: $transperant-50p;
  position: fixed;
  font-size: 25px;
  z-index: 1000;
}
.k-tooltip {
  background-color: $blue-dark !important;
  padding: 12px 16px;
  line-height: normal;
  word-wrap: break-word;
  .dark & {
    background-color: $transparent-35p !important;
    color: $white;
    border-radius: 0.5em;
    padding: 0.4em 0.8em;
    font-weight: 400;
    font-size: 0.85rem;
    line-height: 1.25rem;
    & .k-callout {
      color: $transparent-35p !important;
    }
  }
}
.k-callout {
  color: $blue-dark !important;
  background-color: transparent !important;
}
.action-icon {
  width: 1em;
  height: 1em;
  font-size: 18px;
  display: inline-block;
  cursor: pointer;
  color: $secondary-blue;
  .k-svg-icon {
    width: 18px !important;
    height: 18px !important;
  }
}
.k-switch-handle {
  transform: translateX(0) !important;
  border-radius: 6em;
  width: 2.5em;
  height: 2.5em;
  border-width: 1px;
  border-style: solid;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  left: 0;
  transition: left 200ms ease-out 0s;
}
.k-switch-on .k-switch-track {
  color: $white;
  background-color: $secondary-blue;
  width: 55px;
  &:hover {
    background-color: $secondary-blue;
    width: 55px;
  }
}
.k-switch {
  box-shadow: none;
  height: 25px;
  width: 60px;
}
.amount-input {
  width: 120px;
  background-color: $grey-dark;
  .k-numeric-wrap {
    span.k-select {
      display: none !important;
    }
  }
}
h1 {
  font-size: 1.6667em;
  font-family: $primary-font;
  margin-bottom: 0.6667em;
}
h2 {
  font-size: 1.3333em;
  font-family: $primary-font;
  margin: 0.83333em 0;
}
.help-block {
  display: inline-block;
  color: $red;
}
.agreement-header {
  span {
    font-weight: bold;
    margin-left: 0.75em;
  }
}
.programTypeDelete {
  padding-left: 1.5em;
  color: $red !important;
  text-transform: uppercase;
}
.list-box-title {
  font-family: $primary-font;
}
.page-wrap {
  display: inline-block;
  width: 100%;
  .list-box-wrap {
    height: 21em;
    overflow-y: auto;
  }
}
.card {
  box-shadow: 0 4px 8px 0 $grey-dark;
  transition: 0.3s;
  height: 3em;
  background-color: $grey-lighter;
  padding: 5px;
  font-size: 1.3em;
  font-weight: bold;
  border-radius: none !important;
}
.card:hover {
  box-shadow: 0 8px 16px 0 $grey-dark;
}
.card-container {
  padding: 5px;
  border-radius: 0 0 3px 3px;
  font-weight: bold;
  background-color: $grey-ultra-dark;
  box-shadow: 0 4px 8px 0 $transparent-80p;
  transition: 0.3s;
  font-size: 1em;
  color: $white;
  font-family: sans-serif;
}
.card-main-container {
  width: 43%;
  border-radius: 0.25em;
  border: 1px solid $transperant-95p;
}
.batch-history {
  cursor: pointer;
  color: $secondary-blue !important;
}
.custm-tab {
  .k-tabstrip > .k-tabstrip-content {
    border: none !important;
    outline: none;
  }

  .k-tabstrip-items-wrapper > .k-tabstrip-items .k-item {
    color: $grey-darkest;
  }

  .k-tabstrip-items-wrapper > .k-tabstrip-items .k-item.k-active,
  .k-tabstrip-items-wrapper > .k-tabstrip-items .k-item.k-state-selected {
    border-color: $primary-green;
    color: $white;
    background-color: $primary-green;
  }

  .k-tabstrip-items {
    border-color: $primary-green;
    color: $primary-green;
  }

  .top_0 {
    top: 0.5px !important;
  }

  .k-tabstrip-top > .k-tabstrip-items-wrapper .k-item {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    margin-bottom: -1px;
  }
  .k-tabstrip-top > .k-tabstrip-items-wrapper > .k-tabstrip-items {
    border-bottom-width: 1px;
  }

  .k-tabstrip-top > .k-tabstrip-items-wrapper {
    border-bottom-width: 1px;
    margin-bottom: 1px;
    border-color: $primary-green;
  }
}
.k-tabstrip > .k-dialog-content {
  padding: 7px 12px;
  outline: none;
  z-index: auto;
}
.person-card {
  width: 100%;
  height: auto;
  font-family: Segoe UI;
  font-size: 1em;
  background-color: $grey-ultra-lighter;
  font-weight: unset;
  .border-bottom-solid {
    border-bottom: 1px solid $grey-lighter;
  }
}
.people-card-title {
  color: $primary-green;
}
.k-tabstrip {
  font-size: 1em;
  .k-disabled {
    background-color: inherit !important;
  }
}
.create-person {
  .k-tabstrip > .k-dialog-content {
    border: none !important;
  }
  .k-tabstrip-items .k-item {
    color: $grey-darkest;
  }
  .k-tabstrip-items .k-item.k-active,
  .k-tabstrip-items .k-item.k-state-selected {
    border-color: $primary-green;
    color: $white;
    background-color: $primary-green;
  }
  .k-tabstrip-items {
    border-color: $primary-green;
    color: $primary-green;
  }
  .top_0 {
    top: 0.5px !important;
  }
}
.rate-icon-blue {
  background: url('../../assets/images/rates-blue.svg') no-repeat;
  width: 1em;
  height: 1em;
  font-size: 18px;
  display: inline-block;
}
.nameRow {
  margin-left: -1.5em;
  margin-top: -1.5em;
  height: 2.5em;
  .k-svg-icon {
    font-size: 30px !important;
  }
  .action-item-wrap {
    padding-right: 0.5em;
  }
  &-export {
    margin-left: -1.5em;
  }
}
.exportRow {
  margin-left: -1.5em;
  .k-svg-icon {
    width: 40px !important;
    height: 40px !important;
    margin-top: -0.1em;
    svg {
      width: 40px !important;
      height: 40px !important;
    }
  }
  .action-item-wrap {
    padding-right: 0.5em;
  }
}
.tabContainer {
  .k-dialog-content {
    overflow-x: hidden !important;
  }
}
.user-details {
  background: url('../../assets/icons/nav-icons/UserDetails_Black.svg') no-repeat;
  width: 1.8em;
  height: 1.4em;
  display: inline-block;
}
.k-active {
  .user-details {
    background: url('../../assets/icons/nav-icons/UserDetails_White.svg') no-repeat;
    width: 1.8em;
    height: 1.4em;
    display: inline-block;
  }
  .person-type-details {
    background: url('../../assets/icons/nav-icons/PersonTypeDetails_White.svg') no-repeat;
    width: 1.8em;
    height: 1.4em;
    display: inline-block;
  }
}
.person-type-details {
  background: url('../../assets/icons/nav-icons/PersonTypeDetails_Black.svg') no-repeat;
  width: 1.8em;
  height: 1.4em;
  display: inline-block;
}
.list-tab {
  padding-left: 1.5em !important;
}
.card-btn {
  height: 2em;
  margin-left: -1.5em;
}
.div-wrap-border {
  border: 1px solid $grey-darker;
  border-radius: 5px !important;
  padding: 20px 20px 10px 20px;
}
.inline-error {
  color: $red;
  display: block;
  min-height: 20px;
}
.div-visible-disable {
  .k-textbox:disabled,
  .k-numerictextbox input.k-input:disabled,
  .k-datepicker input.k-input:disabled,
  .k-combobox input.k-input:disabled,
  .k-maskedtextbox input.k-textbox:disabled {
    color: $black !important;
    background-color: $grey-lighter !important;
  }
  .k-maskedtextbox input.k-textbox:disabled {
    opacity: 1 !important;
    border: 1px solid $grey-darker !important;
  }
}
.lookup-header-width {
  max-width: 10em;
  margin-left: -0.1em;
  overflow-wrap: break-word;
}
.person-lookup-header {
  margin: -39px 10px 10px 215px;
  position: fixed;
  width: 30%;
  z-index: 999999;
}
.header-back-button {
  margin: -5.5em -1em 0 2em;
  text-align: right;
}

.custom-panel-content {
  padding-bottom: 20px;
  padding-top: 20px;
}
.custom-panel {
  .k-panelbar {
    border: none;
    background: none;
    padding-bottom: 40px;
    .k-item {
      border-top: none;
      .pnl-title {
        padding-left: 5px;
      }
      & > span.k-link {
        background-color: $white;
        border-bottom: 1px solid $primary-green !important;
      }
      .k-link {
        color: $grey-darkest;
        .k-svg-icon {
          color: $primary-green;
        }
        .k-svg-icon.k-panelbar-collapse:before {
          content: '\e015';
        }
        .k-svg-icon.k-panelbar-expand:before {
          content: '\e013';
        }
      }
      .k-link.k-state-selected {
        color: $grey-darkest;
        background-color: $white;
        border: none;
      }
      .k-link.k-state-focused {
        box-shadow: none;
      }
    }
  }
}
.try-date-help-block {
  display: inline-block !important;
  height: 20px;
  color: $red;
  width: 300px !important;
}
.try-grd-date-help-block {
  display: inline-block !important;
  height: 20px;
  color: $red;
  width: 200px !important;
  word-break: break-word !important;
}

.adjust-alignment {
  padding-top: 35px;
}

.k-button:disabled,
.k-button.k-disabled {
  opacity: 0.3;
}

.k-datepicker > .k-dateinput > input.k-input-inner {
  padding: 8px 8px;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

.k-grid-pager {
  border-width: 0 0 0 !important;
  border: none !important;
}

.custom-panelbar-item {
  .k-panelbar > .k-panelbar-header {
    span.k-link {
      color: $grey-darkest !important;
      background-color: $white;
      border-bottom: 1px solid $primary-green;
      padding: 12px 4px;
    }
    span.k-cell-inner,
    .k-grid-pager {
      .k-link {
        background-color: $grey-lighter;
        border-bottom: none;
      }
    }
    span.k-link.k-selected.k-focus {
      color: $grey-darkest;
      background-color: $white !important;
      border-bottom: 1px solid $primary-green;
    }

    & .k-link.k-selected:hover {
      background-color: $white;
      color: $grey-darkest;
      box-shadow: none;
      border: none;
    }
    & .k-link:focus {
      box-shadow: none;
      border: none;
    }
  }
  .k-panelbar > .k-panelbar-header + .k-panelbar-header {
    border: none;
  }
}
.k-checkbox-label {
  align-items: center !important;
}

.k-checkbox {
  margin-right: 5px;
}

.k-popup>.k-list {
  height: 100%;
  border-width: 0;
}

.default-disabled {
  .k-disabled {
    background-color: $grey-lighter !important;
  }
}
