@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .header-bar {
        width: calc(100% - 20.5em) !important;
    }
    .k-loading-image .k-loading-image ::before {
        margin-top: -0.5em;
        margin-left: -0.5em;
        width: 1em;
        height: 1em;
        position: absolute;
        top: 50%;
        left: 50%;
    }
    .k-svg-i-calendar {
        font-size: 0.8889em !important;
        height: 100%;
        display: inline-block;
        padding-top: 2px;
        margin: 0 !important;
        &::before {
            background: url("../../assets/icons/Calendar.svg") no-repeat;
            width: 1.48em;
            height: 1.3333em;
            display: inline-block;
            background-size: 1.45em !important;
        }
    }
    .form-wrap {
        .k-svg-i-calendar {
            font-size: 18px;
            height: 100%;
            display: inline-block;
            padding-top: 2px;
            margin: 0 !important;
            &::before {
                background: url("../../assets/icons/Calendar.svg") no-repeat;
                width: 1.48em;
                height: 1.3333em;
                display: inline-block;
                background-size: 1em !important;
            }
        }
    }
    .custom-grid-vertical-scroll {
        height: 300px !important;
    }
    .form-wrap-customdatepicker {
        .k-form-field {
            .formControlTabAccessible {
                span.k-svg-i-calendar {
                    margin-top: -7px !important;
                }
            }
        }
    }
    .k-loading-image::before,
    .k-loading-image::after {
        font-size: 64px !important;
    }
    /*IE Close icon position*/
    .k-svg-i-close {
        margin-right: 20px;
    }
    /*IE Clear text icon removal*/
    .k-clear-value {
        display: none !important;
    }
    /*IE Kendo grid filter icon position*/
    .k-grid-filter {
        margin-right: 8px !important;
    }
    .k-tabstrip {
        font-size: 1em !important;
    }
    .form-wrap .k-form-field .k-datepicker span.k-select {
        right: 5px !important;
    }
    .el-with-icon{
        & .k-textbox, & .action-icon{
            float:left;
        }
    }
    .k-grid-edit-row .k-datepicker, .k-grid-add-row .k-datepicker{
        width: 100%!important;
    }
}
