.header-bar {
  height: 5em;
  display: inline-block;
  background: $primary-green;
  font-size: 0.889em;
  color: $white;
  box-sizing: border-box;
  text-align: right;
  div {
    float: right;
    padding-bottom: 0;
  }
  label {
    margin-left: 1.667em;
    float: right;
    padding: 1.667em 1.5em;
    margin-top: 0.15em;
    select {
      background: $transparent;
      border: none;
      color: $white;
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      padding-right: 0.7em;
      &::-ms-expand {
        display: none;
      }

      &:focus {
        outline: none;
      }

      option {
        color: $blue-ultra-dark;
      }
    }

    &::after {
      background: url("../../assets/icons/NameDropdown.svg") no-repeat;
      width: 10px;
      height: 12px;
      display: inline-block;
      box-sizing: border-box;
      position: relative;
      top: 3px;
      pointer-events: none;
    }
    svg {
      display: none;
    }
  }
  a {
    margin-left: 1.667em;
    float: right;
    padding-top: 1.667em;
    padding-bottom: 1.667em;
    color: $white;
    &.logout-text {
      margin-top: 0.15em;
    }
    i.more {
      background: url("../../assets/icons/3dots.svg") no-repeat;
      width: 15px;
      height: 15px;
      margin-top: 3px;
      display: inline-block;
    }
  }
}

.navbar {
  width: 17.944em;
  height: 3.85em;
  padding: 0;
  float: left;
  position: relative;
  .logo-wrap {
    text-align: center;
    width: 100%;
    img {
      padding: 0.5em 0 0;
      width: 10em;
    }
    button {
      padding: 1em;
      position: absolute;
      right: 0.556em;
      top: 0.5em;
    }
  }
  i {
    font-size: 18px;
    &.collapse-left {
      background: url("../../assets/icons/HamClose.svg") no-repeat;
      width: 0.778em;
      height: 1.22em;
      display: inline-block;
    }
    &.expand-list {
      background: url("../../assets/icons/Ham.svg") no-repeat center;
      width: 1.5em;
      height: 1.5em;
      float: left;
      background-size: 1.25em;
    }
  }
}
.company-dropdown-wrap {
  kendo-dropdownlist {
    width: 7.3em;
  }
  .k-dropdown {
    background: $primary-green !important;
    box-shadow: 0 0 0;
    .k-dropdown-wrap {
      background: $primary-green !important;
      color: $white;
      border-width: 0;
      box-shadow: 0 0 0;
      .k-input {
        font-size: 0.889em;
      }
    }
  }
}
.onFocus-company-dropdown-wrap {
  background: $white;
  color: $grey-ultra-darker;
  height: 100% !important;
  kendo-dropdownlist {
    width: 7.3em;
  }
  .k-dropdown {
    background: $white !important;
    box-shadow: 0 0 0;
    .k-dropdown-wrap {
      background: $white !important;
      color: $grey-ultra-darker;
      border-width: 0;
      box-shadow: 0 0 0;
      height: 2.5em;
      .k-input {
        height: 2.5em;
        font-size: 0.889em;
      }
    }
  }
}
.headerwrap {
  border-bottom: 1px solid $primary-green !important;
  margin-bottom: 1em;
}
