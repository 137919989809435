// BUTTONS
.cdms-btn {
  color: $white !important;
  font-family: $primary-font;
  font-size: 1em;
  height: 2.5em;
  width: auto;
  min-width: 9.3333em;
  padding: 0 10px !important;
  margin-right: 1.11em;
  border: none;
  border-radius: 2px;
  word-break: break-all;
  background-image: none !important;
  box-shadow:
    0 2px 6px $grey-dark,
    0 2px 3px $black-transparent;

  &:hover {
    color: $white !important;
    opacity: 0.75;
    transition-duration: 500ms;
    transition-property: opacity;
  }

  &:focus {
    border: thin !important;
    border-style: solid !important;
    border-color: $black !important;
  }

  &.disabled {
    cursor: not-allowed;
  }

  span {
    margin: 0px !important;
    margin-right: 4px !important;
    font-size: 14px;
  }

  &-blue {
    @extend .cdms-btn;
    background: $secondary-blue !important;
  }

  &-green {
    @extend .cdms-btn;
    background: $primary-green !important;
  }

  &-red {
    @extend .cdms-btn;
    background: $red !important;
  }

  &-white {
    @extend .cdms-btn;
    background: $white !important;
    color: $secondary-blue !important;
    border: $secondary-blue !important;
    border-width: 1px !important;
    border-color: $secondary-blue !important;
    border-style: solid !important;
  }

  &-grey {
    @extend .cdms-btn;
    background: $grey !important;
    color: $grey-ultra-darker !important;

    &:hover {
      color: $grey-ultra-darker !important;
    }

    &-dark {
      position: absolute !important;
      top: 90px;
      right: 30px;
      background: $grey-ultra-darker !important;
      color: $white !important;
    }

    &-light {
      @extend .cdms-btn;
      background: $grey-lighter !important;
      color: $white !important;

      &:hover {
        background: $grey-ultra-light !important;
      }
    }
  }

  &-save {
    @extend .cdms-btn;
    background-color: $primary-green !important;

    &:hover {
      border-color: $primary-green !important;
      background-color: $primary-green !important;
      background-image: inherit;
    }
  }

  &-cancel {
    @extend .cdms-btn;
    background: $grey !important;
    color: $grey-ultra-darker !important;

    .k-svg-icon {
      color: $red !important;
    }

    .k-button-text {
      color: $grey-ultra-darker !important;
    }

    &:hover {
      border-color: $grey;
      color: $grey-ultra-darker !important;
      background-color: $grey !important;
      background-image: inherit;
    }
  }

  &-export {
    @extend .cdms-btn;
    background: $secondary-blue !important;
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center !important;

    span {
      vertical-align: text-top;
      padding: 0 !important;
      line-height: 14px !important;
    }

    i {
      margin-right: 5px !important;
      font-size: 20px !important;
      color: $white !important;
    }
  }

  &-grid-cmd {
    width: 40px;
    height: 40px;
    border-color: inherit;
    border-style: none;
    color: inherit;
    background-color: inherit;
    background-image: none;

    span {
      color: $secondary-blue !important;
      font-size: 18px !important;
    }
  }
}

.cdms-btn-sm {
  margin-right: 0.5em;
  color: $white;
  height: 2.25em;
  width: 2.25em;
  border-radius: 50% !important;

  span {
    height: -webkit-fill-available;
  }

  .k-svg-icon {
    margin-top: 0;
    font-size: 20px;
    color: $white;
  }
}

.cdms-btn-green-sm {
  @extend .cdms-btn-sm;
  background: $primary-green;
  border: $primary-green;
}

.cdms-btn-red-sm {
  @extend .cdms-btn-sm;
  background: $red-light;
  border: $grey;
}

.cdms-btn-grey-sm {
  @extend .cdms-btn-sm;
  background: $grey;
  border: $grey;
}

.cdms-btn-white-sm {
  @extend .cdms-btn-sm;
  background: $white;
  border: $white;
}

.cdms-icon-btn {
  margin-right: 0.5em;
  color: $white;
  background: $white;
  height: 2.75em;
  width: 2.75em;
  border: $white;

  .k-svg-icon {
    margin-top: 0;
    font-size: 17px;
    color: $grey-ultra-darker !important;
  }

  svg {
    width: 18px !important;
    height: 18px !important;
  }
}

.cdms-action-head-btn {
  @extend .cdms-btn;
  display: inline-flex !important;
  align-items: center !important;
  justify-content: center !important;
  margin-right: 0.25em !important;
  transition: 0.3s;
  min-width: 7.5em !important;
  box-shadow: none;

  &:focus {
    border: inherit !important;
    border-style: inherit !important;
    border-color: inherit !important;
  }

  &-save,
  &-export,
  &-refresh,
  &-create,
  &-cancel,
  &-delete,
  &-edit,
  &-confirm,
  &-copy,
  &-history,
  &-blue {
    @extend .cdms-action-head-btn;
    background: $blue-dark !important;
    display: flex !important;
    align-items: center !important;
    min-width: 5.9em !important;
    height: 100%;

    span {
      color: $white !important;
      font-size: 1em !important;
      margin: 0 !important;
      padding: 0 !important;
      display: flex;

      &.k-svg-icon {
        margin-right: 5px !important;
        font-size: 20px !important;
      }
    }

    i {
      margin-right: 5px !important;
      font-size: 20px !important;
      color: $white !important;
    }
  }

  &-refresh {
    min-width: 50px;
    margin: 0 !important;

    .k-svg-icon.k-svg-icon-refresh:only-child {
      font-size: 1.5em !important;
    }

    .k-svg-icon.k-svg-i-refresh:only-child {
      font-size: 1.5em !important;
    }

    &-wide {
      @extend .cdms-action-head-btn-refresh;
      min-width: 9.3333em;
    }
  }

  &-cancel {
    svg {
      color: $red !important;
    }
  }

  &-confirm {
    svg {
      color: $green-light !important;
    }
  }

  &-blue {
    background: $secondary-blue !important;
    display: inline-flex !important;
    height: 2.5em;
    min-width: 7.5em !important;
  }

  &-bluedark {
    color: $white;
    background: $blue-dark !important;
    border: $blue-dark !important;
    display: inline-flex !important;
    height: 2.5em;
    min-width: 7.5em !important;
  }

  &-grey {
    @extend .cdms-action-head-btn;
    background: $grey !important;
    color: $grey-ultra-darker !important;
    height: 2.5em;
    min-width: 7.5em !important;
    &:hover {
      color: $grey-ultra-darker !important;
    }
  }

  &-green {
    @extend .cdms-action-head-btn;
    background: $primary-green !important;
    color: $white !important;
    height: 2.5em;
    min-width: 7.5em !important;
  }
}

.cdms-action-footer-btn {
  @extend .cdms-btn;

  span {
    margin-right: 4px !important;
  }

  &-save {
    @extend .cdms-btn-save;
    @extend .cdms-action-footer-btn;
    background: $primary-green !important;
  }

  &-export {
    @extend .cdms-btn-export;
    @extend .cdms-action-footer-btn;
  }

  &-cancel {
    @extend .cdms-btn-cancel;
    @extend .cdms-action-footer-btn;
  }

  &-blue {
    @extend .cdms-btn-blue;
    @extend .cdms-action-footer-btn;
  }

  &-green {
    @extend .cdms-btn-green;
    @extend .cdms-action-footer-btn;
  }

  &-grey {
    @extend .cdms-btn-grey;
    @extend .cdms-action-footer-btn;
  }

  &-white {
    @extend .cdms-btn-white;
    @extend .cdms-action-footer-btn;
    &:hover {
      color: $secondary-blue !important;
    }
  }
}

// Buttons in the grid
.action-buttons,
kendo-dialog-actions {
  & btn-with-tool-tip {
    label {
      font: 1em;
      font-family: $primary-font;
      border: none;
      border-radius: 2px;
    }
  }
}

.side-nav-collapsed {
  .action-footer {
    width: 100% !important;
    left: 0 !important;
    margin: 0 !important;
  }
}

.action-footer {
  background: $grey-lighter;
  height: 4.0556em;
  text-align: right;
  padding: 0.75em 2.22em;
  margin: 0 -2.223em;
  bottom: 0;
  left: 19.944em;
  z-index: 3;
  width: calc(100% - 17.75em);
  position: fixed;
}

.action-footer-modal {
  background: $grey-lighter;
  height: 4.0556em;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  label {
    margin-bottom: 0 !important;
  }
}

.req-star {
  color: $red;
  margin: 0;
  margin-left: 3px;
  display: inline;
}

input[type='date']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  display: none;
}

a {
  cursor: pointer;
  text-decoration: none !important;
  display: inline-block;

  i {
    font-size: 18px;
  }

  span {
    display: inline-block;
    padding: 0 0.4em;
    font-size: 1em;
  }
}

button {
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

button,
a,
li {
  &:focus {
    box-shadow: none !important;
    outline: none !important;
  }
}

kendo-grid-list{
  .form-wrap,
  .form-wrap-customdatepicker {
    padding-bottom: 0em !important;
  }
}

/* Date picker styles*/
.form-wrap,
.form-wrap-customdatepicker {
  border-bottom: 1px solid $grey-darker;
  padding-bottom: 2.22em;

  .k-form-field {
    display: inline-block;
    position: relative;
    margin-bottom: 0.5em;

    .combobox-wrap-sm.k-combobox button.k-input-button {
      position: absolute;
      right: 5px;
      top: 13px;
      background: url('../../assets/icons/CollapseBlack.svg') no-repeat !important;
      width: 15px;
      height: 10px;
      margin-right: 0 !important;

      .k-svg-i-caret-alt-down{
        svg{
          display: none !important;
        }
      }
    }

    &.look-over-btn-wrap {
      & .look-over-btn {
        top: 0.5em;
      }
    }

    .look-over-btn {
      position: absolute;
      background: url('/assets/icons/Lookup.svg') no-repeat !important;
      width: 30px;
      height: 25px;
      right: 7px;
      top: 27px;
    }

    span {
      display: block;
      margin-top: 0;
    }

    span[role='listbox'] {
      display: flex;
      margin-top: 0;
      width: 18.75em;
      height: 2.75em;
      font-size: 0.8889em;
      border: 1px solid $grey-darker;
      box-shadow:
        0 2px 6px $grey-dark,
        0 2px 3px $transperant-95p;
      background: $white;
    }

    input {
      width: 18.75em;
      min-height: 2.75em;
      height: 100%;
      font-size: 0.8889em;
      border: 1px solid $grey-darker;
      box-shadow:
        0 2px 6px $grey-dark,
        0 2px 3px $transperant-95p;
    }

    .k-textbox{
      height: 2.75em;
    }
  }

  .k-numerictextbox {
    width: 16.667em;
    font-size: .8889em !important;

    input{
      width: 100%;
    }

    .k-dropdown-wrap {
      span.k-select {
        position: absolute;
        right: 6px;
        top: 15px;
        background: url('../../assets/icons/CollapseBlack.svg') no-repeat !important;
        width: 15px;
        height: 10px;
        margin-right: 0;

        .k-svg-i-arrow-s {
          svg{
            display: none !important;
          }
        }
        .k-svg-i-caret-alt-down{
          svg{
            display: none !important;
          }
        }
      }
    }

    .k-numeric-wrap,
    .k-dropdown-wrap {
      border: none;

      input {
        width: 18.75em;
        height: 2.75em;
        font-size: 0.8889em;
        border: 1px solid $grey-darker;
        box-shadow:
          0 2px 6px $grey-dark,
          0 2px 3px $transperant-95p;

        &:disabled {
          background: $grey-lighter;
        }
      }

      span.k-select.ng-star-inserted {
        display: none;
      }
    }

    .k-numeric-wrap {
      span.k-select {
        display: none;
      }
    }

    input.k-input-inner {
      border: none;
      box-shadow: none;
    }
  }

  .custom-timepicker-stretch {
    &.k-timepicker {
      font-size: 1em;
      width: 16.6667em;
    }
  }

  .k-timepicker {
    span.k-select {
      display: none;
    }
  }

  .k-dateinput {
    input.k-input-inner {
      width: 100%;
      min-height: 2.75em;
      height: 100%;
      font-size: 1em;
      border: 1px solid $grey-darker;
      box-shadow:
        0 2px 6px $grey-dark,
        0 2px 3px $transperant-95p;

      &:disabled {
        background: $grey-lighter;
      }
    }
  }

  .k-datepicker {
    font-size: 1em;
    width: 16.6667em;

    .k-input-button {
      position: absolute;
      right: 0;
      display: inline-block;
      width: 2.27778em;
      height: 100%;
      background: $white-transparent !important;
      border: none;

      .k-svg-i-calendar {
        font-size: 16px;
        height: 100%;
        width: 100%;
        display: inline-block;
        margin: 0;

        &::before {
          background: url('../../assets/icons/Calendar.svg') no-repeat;
          width: 1.48em;
          height: 100%;
          display: inline-block;
          background-size: 18px;
        }
      }
    }

    .k-dateinput {
      font-size: .8889em;
    }
  }

  .k-disabled {
    .k-input-button {
      background: $grey-lighter;
    }
  }

  .k-textbox,
  .k-textarea {
    &:disabled {
      background: $grey-lighter;
    }
  }

  .k-maskedtextbox {
    width: 18.75em;
    height: 2.75em;
    font-size: 0.8889em;
  }

  &.form-wrap-sticky-header {
    position: sticky;
    z-index: 50;
    top: 1px;
    background-color: $white;
    width: 100%;
  }

  &.form-wrap-stretch {
    .k-form-field {
      input {
        width: 100%;
        &:focus-visible {
          outline: 0;
        }
      }
    }

    .k-numerictextbox {
      width: 100%;

      .k-input-inner {
        border: none;
        box-shadow: none;
      }

      .k-numeric-wrap,
      .k-dropdown-wrap {
        input {
          width: 100%;
        }
      }
    }

    .k-datepicker {
      width: 100%;
    }

    .k-maskedtextbox {
      width: 100%;
    }
  }

  &.form-wrap-fixed-width {
    .k-form-field {
      .k-datepicker {
        width: 16.6667em;
      }

      .k-dateinput {
        input {
          width: 18.75em;
        }
      }
    }

    .k-numerictextbox,
    .k-combobox {
      .k-numeric-wrap,
      .k-dropdown-wrap {
        input {
          width: 18.75em;
        }
      }
    }
  }

  &.form-wrap-smallIcn {
    span.k-button-icon.k-svg-icon.k-svg-i-caret-alt-down {
      display: inline-flex;

      &::before {
        content: '\e015';
      }
    }

    .k-dropdown-wrap {
      span.k-select {
        position: absolute;
        right: 4px;
        top: 15px;
        background: url('../../assets/icons/CollapseBlack.svg') no-repeat !important;
        width: 9px;
        height: 6px;
        margin-right: 0;

        .k-svg-i-arrow-s {
          svg{
            display: none !important;
          }
        }

        .k-svg-i-caret-alt-down{
          svg{
            display: none !important;
          }
        }
      }
    }
  }

  .k-numerictextbox,
  .k-combobox-popup{
    width: 18.75em !important;
  }

  .k-combobox{
    font-size: .8889em !important;
  }
}

.form-wrap-fixed-width {
  &.k-form-field,
  .k-form-field {
    .k-datepicker {
      font-size: 1em;
      width: 16.6667em;

      span.k-select {
        width: 1.27778em;
        height: 100%;
        background: $white-transparent;
        position: absolute;
        right: -5px;
        top: 1px !important;

        .k-svg-i-calendar {
          font-size: 16px;
          height: 100%;
          display: inline-block;
          padding-top: 2px !important;
          margin: 0 !important;

          &::before {
            background: url('../../assets/icons/Calendar.svg') no-repeat;
            width: 1.48em;
            height: 100%;
            display: inline-block;
            background-size: 18px;
            margin-top: 5px;
          }
        }
      }

      .k-dateinput {
        font-size: 1em;
      }
    }

    .k-dateinput {
      input {
        width: 18.75em;
        height: 2.75em;
        font-size: 0.8889em;
        border: 1px solid $grey-darker;

        &:disabled {
          background: $grey-lighter;
        }
      }
    }
  }

  .k-disabled {
    .k-select {
      background: $grey-lighter !important;
    }
  }

  .k-textbox {
    &:disabled {
      background: $grey-lighter;
    }
  }

  .k-numerictextbox,
  .k-combobox {
    width: 100%;
    min-width: 15em;
    font-size: 1em;

    button.k-input-button {
      position: absolute;
      right: 8px;
      top: 13px;
      background: url('../../assets/icons/CollapseBlack.svg') no-repeat !important;
      width: 15px;
      height: 10px;
      margin-right: 0 !important;

      .k-button-icon {
        svg{
          display: none !important;
        }
      }

      .k-svg-i-caret-alt-down{
        svg{
          display: none !important;
        }
      }
    }

    .k-clear-value {
      display: none !important;
    }

    .k-numeric-wrap,
    .k-dropdown-wrap {
      border: none;

      input {
        width: 18.75em;
        height: 2.75em;
        font-size: 0.8889em;
        border: 1px solid $grey-darker;
        box-shadow:
          0 2px 6px $grey-dark,
          0 2px 3px $transperant-95p;

        &:disabled {
          background: $grey-lighter;
        }
      }

      span.k-select.ng-star-inserted {
        display: none;
      }
    }

    .k-numeric-wrap {
      span.k-select {
        display: none;
      }
    }
  }

  .k-select {
    margin: 0 1em !important;

    .k-svg-i-calendar {
      margin: 0 !important;
    }
  }

  .k-dateinput {
    input {
      height: 2.75em !important;
      box-shadow:
        0 2px 6px $grey-dark,
        0 2px 3px $transperant-95p;
    }
  }
}

.form-wrap-customdatepicker {
  border: none;
  padding: unset;
}

.form-wrap-timepicker {
  width: 8.667em;

  .k-dropdown-wrap {
    span.k-select {
      position: absolute;
      right: 20px;
      top: 15px;
      background: url('../../assets/icons/CollapseBlack.svg') no-repeat !important;
      width: 15px;
      height: 10px;
      margin-right: 0;

      .k-svg-i-arrow-s {
        svg{
          display: none !important;
        }
      }

      .k-svg-i-caret-alt-down{
        svg{
          display: none !important;
        }
      }
    }
  }

  .k-numeric-wrap,
  .k-dropdown-wrap {
    border: none;

    input {
      width: 8.667em;
      height: 2.75em;
      font-size: 0.8889em;
      border: 1px solid $grey-darker;
      box-shadow:
        0 2px 6px $grey-dark,
        0 2px 3px $transperant-95p;

      &:disabled {
        background: $grey-lighter;
      }
    }

    span.k-select.ng-star-inserted {
      display: none;
    }
  }

  .k-numeric-wrap {
    span.k-select {
      display: none;
    }
  }
}

.k-svg-i-sort-asc-small,
.k-svg-i-sort-desc-small {
  color: $black;
}

.k-svg-i-filter {
  color: $grey-darkest;
}

.k-filter-menu-container {
  .k-action-buttons {
    .k-primary {
      background: $secondary-blue;
      color: $white;
    }

    .k-button-solid-primary {
      background: $secondary-blue;
      color: $white;
      border-color: $secondary-blue;
    }
  }
}

.k-widget ::selection,
.k-block ::selection,
.k-panel ::selection,
.k-textbox::selection,
.k-input::selection,
.k-textarea::selection {
  background-color: $secondary-blue;
  color: $white;
}

.k-checkbox:checked:focus + .k-checkbox-label::before {
  box-shadow: none;
}

.k-checkbox:checked + .k-checkbox-label::after {
  color: $grey-ultra-dark;
  box-shadow: none !important;
}

.k-checkbox:checked + .k-checkbox-label::before {
  border-color: $grey-darker !important;
  background-color: $white !important;

  &:disabled {
    background: $grey-lighter !important;
  }
}

.has-error {
  border: 1px solid $red !important;

  .k-dateinput,
  .k-dropdown-wrap,
  .k-numeric-wrap {
    input {
      border: none;
    }
  }

  &-date {
    border: none;

    & input.k-input {
      border: 1px solid $red;
    }
  }

  &.k-combobox-popup {
    border: 1px solid $red;
  }
}

.has-highlight {
  border: 2px solid $green-light;

  .k-dateinput,
  .k-dropdown-wrap,
  .k-numeric-wrap {
    input {
      border: none;
    }
  }
}

.k-datepicker {
  border: none;
  height: 2.5em;
  &:hover {
    border-color: $transparent-85p !important;
    transition-duration: 500ms;
    transition-property: border-color;
  }
  .k-input-inner {
    &:hover {
      border-color: $transparent-85p !important;
      transition-duration: 500ms;
      transition-property: border-color;
    }
  }
}

.k-calendar {
  .k-calendar-navigation .k-dialog-content li {
    &:hover {
      color: $secondary-blue;
    }
  }

  .k-calendar-header .k-today {
    color: $secondary-blue;

    &:hover {
      color: $secondary-blue;
    }
  }

  .k-state-selected {
    & > .k-link {
      color: $white;
      background: $secondary-blue !important;
    }
  }

  .k-today {
    .k-link {
      font-weight: bold;
    }
  }
}

.k-combobox-popup {
  font-size: 0.8889em !important;
  border: 1px solid $grey-darker;
  box-shadow:
    0 2px 6px $grey-dark,
    0 2px 3px $transperant-95p;
  padding: 0;

  .k-dropdown-wrap {
    border: none;
  }

  span.k-select {
    display: none;
  }
  .k-chip-list {
    .k-chip .k-chip-actions {
      margin: 0em 0 !important;
    }
    .k-searchbar {
      input.k-input-inner {
        border: 1px solid $grey-darker;
        box-shadow:
          0 2px 6px $grey-dark,
          0 2px 3px $transparent-95p;
      }
    }
  }

  input.k-input-inner {
    width: 100%;
    height: 100%;
    border: none;
    box-shadow: none;
    padding: 4px 8px;
  }

  &:disabled {
    background: $grey-lighter;
  }

  .k-input-button {
    background-image: none !important;
    .k-button-icon {
      .k-svg-i-caret-alt-down {
        display: none;
      }
    }
  }
  .k-searchbar .k-input-inner {
    height: 100%;
    border: none;
  }

  .k-button:hover {
    border-color: $white;
    color: $white;
    background-color: $white;
    background-image: none;
  }
}

.k-searchbar-hide{
  &.k-combobox-popup .k-chip-list .k-searchbar{
    display: none !important;
  }
}

.btn-checkoverlap {
  height: 2.5em;
  width: 16.3333em;
  font: 1em;
  font-family: $primary-font;
  border: none;
  border-radius: 2px;
  margin-right: 1.11em;
  background: $secondary-blue;
  color: $white;
}

label.k-checkbox-label {
  &::before {
    border-color: $grey-darker !important;
    padding-left: inherit !important;
  }
}

.form-multiselect-scroll {
  width: 18.75em;
  font-size: 0.8889em;
  border: 1px solid $grey-darker;
  box-shadow:
    0 2px 6px $grey-dark,
    0 2px 3px $transperant-95p;
  max-height: 5em;
  height: 5em;
  overflow-y: scroll;
  overflow-x: hidden;

  .k-multiselect {
    border: none !important;
    box-shadow: none !important;
    .k-searchbar {
      margin: 0px;
      input {
        border: none;
        box-shadow: none;
      }
    }
    .k-chip{
      display: flex;
      align-items: flex-start;
      .k-chip-actions{
        margin: 0px !important;
      }
    }
  }
}

.form-checkbox,
.k-checkbox,
.k-checkbox:focus {
  min-height: 14px !important;
  height: 14px !important;
  width: 14px !important;
}

.form-checkbox-container {
  height: 40px !important;
  display: inline-block;
  position: relative;
  margin-bottom: 1.5em;
  margin-top: 15px;
}

.ng-invalid .k-multiselect-wrap .k-searchbar .k-input-inner {
  color: $black;
}

.custom-dropdown-stretch {
  width: 100%;
  height: 2.45em;
  padding: 5px;
  box-shadow:
    0 2px 6px $grey-dark,
    0 2px 3px $transperant-95p;
  border: 1px solid $grey-darker;
}

.row-timepicker .k-select {
  display: none;
}

div.row-timepicker.custom-timepicker .k-timepicker {
  width: 8.75em;
}
.form-checkbox {
  width: 100% !important;
}

.ctrl-below-text {
  margin: 0;
  color: $grey;
  font-size: 0.8em;
  font-family: $primary-font;
  font-style: italic;
}

.disabled {
  opacity: 0.5;
  cursor: default;
  &:hover {
    opacity: 0.5;
  }
}

.input-comments {
  padding: 5px 10px;
  width: 100%;
  border: 1px solid $grey-darker;
}

.combobox-wrap-sm {
  width: 100%;

  .k-clear-value {
    padding: 0px !important;
    .k-svg-icon.k-svg-i-x {
      margin-left: -3.25em;
      margin-top: -3px;
    }
  }

  .k-dropdown-wrap,
  .k-multiselect-wrap {
    span.k-select {
      position: absolute;
      right: 6px;
      top: 15px;
      background: url('../../assets/icons/CollapseBlack.svg') no-repeat !important;
      width: 15px;
      height: 10px;
      margin-right: 0 !important;

      .k-svg-i-arrow-s {
        svg{
          display: none !important;
        }
      }

      .k-svg-i-caret-alt-down{
        svg{
          display: none !important;
        }
      }
    }
  }

  .k-numeric-wrap,
  .k-multiselect-wrap,
  .k-dropdown-wrap {
    border: none;

    input {
      width: 100%;
      height: 2.75em !important;
      font-size: 0.8889em !important;
      border: 1px solid $grey-darker !important;
      box-shadow:
        0 2px 6px $grey-dark,
        0 2px 3px $transperant-95p;

      &:disabled {
        background: $grey-lighter;
      }
    }

    span.k-select.ng-star-inserted {
      display: none;
    }
  }

  .k-numeric-wrap {
    span.k-select {
      display: none;
    }
  }
}

.custom-control-multiselection {
  padding: 5px;
  width: 100%;
  font-size: 0.8889em;
  max-height: 82px;
  overflow-y: auto;

  ul {
    list-style: none;
    padding-left: 5px;

    li {
      padding: 5px;
      background: $secondary-blue;
      color: $white;
      box-sizing: border-box;
      border-radius: 2.778em;
      font-size: 0.9em;
      margin-right: 0.5em;
      margin-bottom: 0.5em;
      display: inline-block;
      word-break: break-all;

      a.k-svg-i-close {
        font-size: 1.5em;
      }
    }
  }

  &-sm {
    padding-left: 0;

    ul {
      padding: 0;

      li {
        a.k-svg-i-close {
          font-size: 1.25em;
        }
      }
    }
  }
}

.action-button-impersonate {
  margin: -5em 0.77em 0 -1.6em;
  text-align: right;
}

.k-textbox.ng-invalid.ng-touched,
.k-textbox.ng-invalid {
  border-color: $grey-darker;

  &.has-error {
    border-color: $red;
  }
}

.icon {
  &-green {
    color: $green;
  }

  &-exclaimation {
    background: url('../../assets/icons/Exclamation_flat_icon.svg') no-repeat;
    margin-left: 1px;

    &-blue {
      background: url('../../assets/icons/Exclamation_blue_icon.svg') no-repeat;
      margin-left: 1px;
    }
  }
}

.combobox-wrap {
  width: 90%;

  .k-combobox {
    width: 100%;

    span.k-dropdown-wrap {
      margin-top: 0;

      input {
        width: 100%;
      }

      span.k-svg-i-close {
        display: none;
      }

      span.k-select {
        position: absolute;
        right: 6px;
        top: 15px;
        background: url('../../assets/icons/CollapseBlack.svg') no-repeat !important;
        width: 15px;
        height: 10px;
        margin-right: 0;

        .k-svg-i-arrow-s {
          svg{
            display: none !important;
          }
        }

        .k-svg-i-caret-alt-down{
          svg{
            display: none !important;
          }
        }
      }
    }
  }
}

.custom-status-checkbox {
  .k-multiselect-wrap {
    .k-clear-value {
      display: none;
    }
  }

  .k-svg-i-close {
    display: none;
  }
}

.form-checkbox-size {
  height: 16px;
  width: 16px;
}

.dropdown-remove {
  .k-svg-i-caret-alt-down{
    display: none !important;
  }
}

.k-checkbox:checked,
.k-checkbox:checked:focus {
  background-color: $white !important;
  content: '\e118';
  color: $grey-ultra-dark;
  border-color: $grey-lighter !important;
}

.k-checkbox:focus {
  box-shadow: $grey-darker;
  border-color: $grey-lighter !important;
}

.k-checkbox:indeterminate,
.k-checkbox.k-state-indeterminate {
  color: $white;
  background-color: $white;
}

.form-checkbox .k-checkbox {
  float: left;
  height: 16px;
  width: 16px;
}

.form-checkbox .k-checkbox-label {
  margin-top: -3px !important;
  padding-left: 0 !important;
  float: left;
}

.k-multiselect-wrap .k-select .k-svg-i-close {
  position: relative;
  margin-bottom: 10px;
}

.btn-tooltip-wrap {
  padding-top: 15px;
  width: 100%;
}

kendo-multiselect .k-reset {
  width: 16.5em;
  top: 2.5em;
}

.k-multiselect.ng-invalid {
  font-size: 1em;
  border-color: red;
}

.k-multiselect.ng-valid {
  font-size: 1em;
}

.form-text-area {
  width: 18.75em;
  height: 2.75em;
  font-size: 0.8889em;
  border: 1px solid $grey-darker;
  box-shadow:
    0 2px 6px $grey-dark,
    0 2px 3px $transparent-95p;
}

.form-select .k-combobox {
  font-size: 1em;
}

.k-custom {
  &-select {
    width: 18.75em;
    height: 2.75em;
    font-size: 0.8889em;
    border: 1px solid $grey-darker;
    box-shadow:
      0 2px 6px $grey-dark,
      0 2px 3px $transparent-95p;
    padding: 4px 8px;
    background: inherit;

    option {
      padding: 0px !important;
    }

    &:focus {
      outline: none;
      outline-width: 0;
      border: 1px solid $grey-lighter !important;
    }
  }
}

.label.k-checkbox-label::before {
  border-color: $grey-darker;
}

.form-wrap .k-form-field .k-combox-bg,
.form-wrap .k-form-field.k-combox-bg {
  & .k-combobox-popup,
  & .k-input-inner {
    width: 100% !important;
    height: 2.75em;
  }

  & .look-over-btn {
    right: 1.25em;
  }
}

.form-wrap .k-form-field.k-combobox-stretch {
  width: 100%;

  & .k-combobox-popup,
  & .k-input-inner {
    width: 100%;
    height: 2.75em;
  }
}

.form-ctrl {
  height: 2.7em;
  font-size: 0.8889em;
  border: 1px solid $grey-darker;
  box-shadow:
    0 2px 6px $grey-dark,
    0 2px 3px $transparent-95p;
}

.k-switch {
  &-on {
    & .k-switch-track {
      background-color: $primary-green;
    }

    &:hover,
    &.k-state-hove {
      & .k-switch-track {
        background-color: $primary-green;
      }
    }
  }

  &-off {
    & .k-switch-track {
      background-color: $blue-dark;
    }

    &:hover,
    &.k-state-hove {
      & .k-switch-track {
        background-color: $blue-dark;
      }
    }
  }

  &-label {
    &-on {
      color: $white;
      display: inline-block;
    }

    &-off {
      color: $white;
      right: 15px;
      display: inline-block;
    }
  }
}

textarea {
  resize: none;
  font-size: 0.8889em;

  &:disabled {
    background: $grey-lighter;
    opacity: 0.5;
  }
}

select {
  &:disabled {
    background: $grey-lighter;
    border: 1px solid $grey-darker;
    box-shadow:
      0 2px 6px $grey-dark,
      0 2px 3px $transperant-95p;
  }
}

.date {
  &-text {
    display: inline-block !important;
    width: 125px;
    text-align: left;
    font-size: 1.35em;
  }

  &-icon {
    &-only {
      width: 40px !important;
      margin-top: -12px;
      height: 30px !important;

      & kendo-dateinput {
        display: none;
      }

      .date-shift.k-datepicker {
        border: none;
        box-shadow: none;
      }
    }

    &-prev {
      background: url('../../assets/icons/DragBacksome.svg') no-repeat;
      display: inline-block !important;
      width: 20px;
      height: 16px;
      padding-right: 25px;
      cursor: pointer;
      svg{
        display: none !important;
      }
    }

    &-next {
      background: url('../../assets/icons/DragSome.svg') no-repeat;
      display: inline-block !important;
      width: 20px;
      height: 16px;
      cursor: pointer;
      svg{
        display: none !important;
      }
    }
  }
}

.custm-tab {
  & kendo-tabstrip.tabContainer {
    & ul {
      & li.k-item {
        & span.k-link {
          font-weight: bolder;
        }
      }
    }
  }
}

/* button link styles */
.btn {
  &-link {
    &-white {
      background: transparent;
      border: none;
      outline: none;
      color: $white;
    }

    &-icon {
      background: transparent;
      border: none;
      outline: none;
      width: 20px;
      height: 20px;
    }
  }
}

/* form numeric styles */
.numeric-form-field-w100 {
  width: 100%;
}

.k-timepicker {
  height: 2.75em;
  width: 18.75em;
  font-size: .8889em;
  box-shadow:
    0 2px 6px $grey-dark,
    0 2px 3px $transparent-95p;
  border: 1px solid $grey-darker;
  .k-dateinput {
    box-shadow: none !important;
    border: none !important;
    .k-input-inner {
      box-shadow: none !important;
      border: none !important;
    }
  }
  &.border-dark {
    border-color: $grey-darkest;

    &:hover,
    &.k-state-hover,
    &.k-state-focused {
      border-color: $grey-darkest;
    }
  }
}

kendo-buttongroup {
  $rad: 0.2em;
  box-shadow:
    0 2px 6px $grey-dark,
    0 2px 3px $transparent-95p;
  border-radius: $rad;
  padding: 0;

  button.k-button {
    height: 2.75em;
    min-width: 8em;
    padding: 4px 8px;
    background: $white;
    color: $grey-dark;
    border-color: $grey-darker;
    z-index: 2;
    font-size: 0.8889em;

    &:hover {
      background: $blue-ultra-light;
      border-color: $blue-dark;
      color: $blue-dark;
      z-index: 4;
    }

    &.k-state-focused {
      outline: 2px solid $blue-dark;
    }

    &:focus {
      z-index: 3;
    }

    &:first-of-type {
      border-radius: $rad 0 0 $rad;
    }

    &:last-of-type {
      border-radius: 0 $rad $rad 0;
    }

    &.k-state-active {
      background-color: $secondary-blue !important;
      border-color: transparent !important;
      color: $white;
    }

    &.k-button-solid-base.k-selected {
      border-color: transparent !important;
      color: $white;
      background-color: $secondary-blue !important;
    }
  }
}

.lookup-input-small {
  .k-combobox-popup input.k-input-inner {
    width: 90%;
  }
}

.custom-month-calender {
  .date-icon-prev,
  .date-icon-next {
    vertical-align: middle;
    margin-top: 0;
    padding-right: 22px;
  }

  .date-text {
    width: auto;
    text-align: center;
    vertical-align: sub;
    margin: 0 5px 0 5px;
  }

  .date-icon-only {
    padding-top: 2px;
    vertical-align: top;
    height: 18px !important;

    .k-svg-i-calendar {
      padding: 0;
    }
  }
}

.multi-select-wrap {
  &-custom {
    .k-multiselect {
      height: auto;

      .k-multiselect-wrap {
        ul.k-reset {
          border-width: 0 1px;
          top: 2.6em;
        }
      }
    }
  }

  &-fh {
    height: auto !important;

    .k-multiselect {
      display: flex;

      .k-multiselect-wrap {
        position: relative;
        display: inline-block;
        height: auto !important;

        kendo-taglist {
          display: inline-block;

          ul.k-reset {
            margin-top: 2.6em;
            width: 16.5em;
            position: relative;
            display: inline-block;
            top: 0 !important;
          }
        }

        kendo-searchbar {
          position: absolute;
          top: 0 !important;
          height: 35px !important;
        }
      }
    }
  }
}

/* Person Status Date Controls */
.status {
  &-datepicker {
    .k-dateinput {
      input,
      input:disabled {
        height: 2.5em;
      }
    }

    .k-select {
      margin-right: 8px;

      .k-svg-i-calendar {
        padding-top: 2px;
      }
    }
  }
}

.form-wrap {
  &-lookupField {
    .k-combobox-popup {
      height: 2.85em;

      .k-dropdown-wrap {
        margin: 0;
      }
    }
  }

  &-textboxField {
    &.k-textbox {
      height: 2.85em;
    }
  }
}

.custom-textbox-lg {
  .k-textbox,
  .k-textbox:disabled {
    height: 3.05em;
  }
}

.custom-button-absolute {
  position: absolute;
  right: 170px;
}

.custom-multiselect-md {
  &.k-multiselect .k-multiselect-wrap .k-button .k-select {
    margin-left: 3px;

    .k-svg-i-close {
      padding-top: 5px;
    }
  }
}

// Hidden input hides the box and button around a field
// Add more fields under hidden input when needed
.hidden-input {
  pointer-events: none !important;
  border: none !important;
  box-shadow: none !important;
  kendo-dateinput {
    pointer-events: none !important;
    border: none !important;
    box-shadow: none !important;
    input {
      padding: 0 !important;
      padding-block: none !important;
      padding-inline: none !important;
    }
  }

  .k-input-button {
    display: none !important;
  }
}

.form-action-wrap {
  height: 10em;

  .role-input-wrap {
    label {
      display: block;
    }

    .k-autocomplete {
      width: 70%;
    }

    input {
      font-size: 1em;
      width: 100%;
    }

    .k-textbox {
      box-shadow:
        0 2px 6px $grey-dark,
        0 2px 3px $transperant-95p;
      height: 2.778em;
    }
  }

  .multi-select-wrap {
    position: relative;

    .k-multiselect {
      font-size: 1em;
      width: 100%;
      position: static !important;

      .k-searchbar {
        height: 100%;
        width: 100%;

        .k-input-inner {
          height: 100%;
        }
      }
    }

    & > label {
      display: block;
    }

    .k-multiselect.k-input {
      box-shadow:
        0 2px 6px $grey-dark,
        0 2px 3px $transperant-95p;
      height: 2.778em;
      position: static;
      width: 15em;

      .k-svg-icon.k-clear-value.k-svg-i-close {
        display: none;
      }
    }

    ul.k-reset {
      background: $white;
      border: 1px solid $grey-dark;
      box-shadow:
        0 2px 6px $grey-dark,
        0 2px 3px $transperant-95p;
      position: absolute;
      top: 4.75em !important;
      width: 15em;
      z-index: 1;

      li.k-button {
        background: $secondary-blue;
        height: 2.75em;
        color: $white;
        box-sizing: border-box;
        border-radius: 2.778em;
        font-size: 0.667em;
        margin-right: 0.5em;
      }
    }
  }

  &.input-options ul.k-reset {
    top: 2.3em !important;
    width: 16.5em !important;
  }
}

.form-action-wrap-multiselect .multi-select-wrap {
  .k-multiselect-wrap.k-floatwrap {
    height: 2.5em;
    width: 16.5em !important;
    border: 1px solid $grey-darker !important;

    .k-searchbar .k-input-inner {
      font-size: 0.8889em;
    }
  }

  ul.k-reset {
    top: 2.75em;
    margin-right: 1.2em;
  }

  .k-disabled {
    .k-multiselect-wrap.k-floatwrap {
      background-color: $grey-lighter;
    }
  }

  .k-button .k-select {
    padding-bottom: 14px !important;

    .k-svg-i-close {
      display: block !important;
      margin-bottom: 0;
    }
  }
}

.custom-multiselect-stretch {
  .multi-select-wrap {
    .k-multiselect-wrap.k-floatwrap {
      width: 18.5em !important;
    }
  }

  &.input-options ul.k-reset {
    width: 18.25em !important;
  }
}

.form-action-wrap-multiselect-md .multi-select-wrap {
  padding-left: 15px;
  padding-right: 15px;

  .k-multiselect-wrap.k-floatwrap {
    height: 2.5em;
  }

  ul.k-reset {
    top: 3.75em;
    width: 46em;
  }
}

.k-list-ul .k-list-item,
.k-list-optionlabel {
  padding: 0.65em 1em;

  &:hover {
    background: $secondary-blue !important;
    background-color: $secondary-blue !important;
    color: $white !important;
  }

  &.k-selected {
    background: $secondary-blue;
  }
}

.k-list-optionlabel{
  padding-block: 9px !important;
  padding-inline: 14px !important;
}

.k-list[role='listbox'] {
  &.k-selected {
    background: $secondary-blue;
  }

  li {
    height: auto;
  }

  font-size: 0.8889em;
}

cdms-portal-u2-common-smartdate-control {
  box-shadow: none !important;
  border: none !important;
  height: 0 !important;
}

.autocompletebox {
  .k-combobox-popup .k-searchbar .k-input-inner {
    height: 100%;
    border: none;
  }

  kendo-combobox{
    width: 100% !important;
  }

  .k-input-button {
    background-image: none !important;
    background-color: $white;
    border: none;

    span {
      display: none;
    }
  }
}

.coordinator-combobox {
  .k-combobox-popup {
    .k-searchbar .k-input-inner {
      height: 100%;
      border: none;
    }

    .k-button:hover {
      border-color: $white;
      color: $white;
      background-color: $white;
      background-image: none;
    }
  }

  .k-input-button {
    background-image: none !important;
    background-color: $white;
    border: none;

    span {
      display: none;
    }
  }
}

.k-input-button {
  background-image: none !important;
  background-color: $white;
  border: none;

  span {
    display: none;
  }
}

.k-input-inner {
  z-index: 0;
}

.k-checkbox::before {
  content: '\e118';
  width: 1em;
  height: 1em;
  line-height: 1;
  transform: translate(-50%, -50%) scale(0);
  font-family: 'WebComponentsIcons', monospace;
  overflow: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 0.8889em;
}

.k-checkbox:checked,
.k-checkbox.k-checked {
  &::before {
    transform: translate(-50%, -50%) scale(1);
  }
}

.k-rounded-md {
  border-radius: 0px;
}

.k-combobox {
  .k-searchbar {
    .k-input-inner {
      border: none;
      box-shadow: none;
    }
  }
  span.k-button-icon.k-svg-icon.k-svg-i-caret-alt-down:hover {
    color: $black;
  }
}

.k-input-solid {
  border-color: $grey-darker;
}

.k-dropdownlist {
  font-size: 0.9790em;
  height: 2.75em !important;
}

.custom-multiselect {
  &.k-multiselect {
    max-height: 110px;
    overflow: auto;
    height: auto !important;
    min-height: 2.778em !important;
    .k-chip-list {
      padding: 5px 9px !important;
      .k-chip {
        font-size: 0.8889em;
        background: $secondary-blue;
        height: 2.75em;
        color: $white;
        box-sizing: border-box;
        border-radius: 2.778em;
        margin-right: 0.5em;
      }
      .k-searchbar {
        height: 35px;
        width: 40px;
      }
    }
  }
}
.custom-grid-toolbar-hide {
  .custom-grid {
    .k-grid-toolbar {
      display: none !important;
    }
  }
  .k-window-title {
    padding: 0em 0 !important;
  }
}

.k-maskedtextbox {
  border-top-style: none;
  border-right-style: none;
  border-left-style: none;
  box-shadow:
    0 2px 6px $grey-dark,
    0 2px 3px $transparent-95p;
  height: 2.5em;
  .k-input-inner {
    height: 100% !important;
    border-bottom: none !important;
    height: 2.5em;
    &:hover {
      border-color: $transparent-85p !important;
      transition-duration: 500ms;
      transition-property: border-color;
    }
  }
}
.k-combobox,
.k-numerictextbox {
  box-shadow:
    0 2px 6px $grey-dark,
    0 2px 3px $transparent-95p;
  height: 2.75em;
  &:hover {
    border-color: $transparent-85p !important;
    transition-duration: 500ms;
    transition-property: border-color;
  }
  input {
    font-size: 1em !important;
  }
  .k-clear-value {
    padding: 0px;
  }

  .k-input-inner{
    border: 0 !important;
  }
}
.k-combobox.k-disabled {
  .k-searchbar {
    background-color: $grey-lighter;
  }
  .k-input-button {
    background-color: $grey-lighter;
  }
}
.k-numerictextbox.k-disabled {
  background-color: $grey-lighter !important;
}
.k-filtercell,
.k-textarea,
.k-dropdownlist,
.k-checkbox {
  box-shadow:
    0 2px 6px $grey-dark,
    0 2px 3px $transparent-95p;
  &:hover {
    border-color: $transparent-85p !important;
    transition-duration: 500ms;
    transition-property: border-color;
  }
}
.k-checkbox:checked:focus {
  box-shadow:
    0 2px 6px $grey-dark,
    0 2px 3px $transparent-95p !important;
}
.k-textbox {
  box-shadow:
    0 2px 6px $grey-dark,
    0 2px 3px $transparent-95p;
  border: 1px solid $grey-darker;
  height: 2.75em;
  &:hover {
    border-color: $transparent-85p !important;
    transition-duration: 500ms;
    transition-property: border-color;
  }
}
.k-chip-list {
  .k-searchbar {
    .k-input-inner:disabled {
      background: none !important;
    }
  }
  .k-chip-label{
    label{
      margin-bottom: 0px !important;
    }
  }
}
.k-input-spinner {
  width: calc(1.4285714286em + 8px);
  flex: none;
  display: flex !important;
  flex-flow: column nowrap;
}
kendo-grid {
  .k-checkbox {
    box-shadow: none !important;
  }
  .k-datepicker {
    border: 1px solid $grey-darker;
  }
}
autocomplete-box {
  &.m-r-1{
    & .look-over-btn{
        margin-right:5px!important;
    }
  }
  div {
    kendo-combobox {
      height: 2.75em !important;
      width: 18.75em;
      button {
        width: 2.27778em !important;
      }
    }
  }
  .k-svg-i-caret-alt-down{
    svg{
      display: none !important;
    }
  }
  .k-textbox{
    width: 18.75em;
  }
  .k-clear-value{
    padding: inherit !important;
    padding-inline: 8px !important;
  }
  .k-input-inner{
    font-size: 1em !important;
  }
}

.k-toolbar::before {
  margin-inline-start: 0px !important;
}
.k-animation-container {
  .k-button-solid-primary {
    border-color: $secondary-blue;
    background-color: $secondary-blue;
  }
}

.k-calendar .k-calendar-td.k-selected .k-link {
  background-color: $secondary-blue;
  &:hover {
    background-color: $secondary-blue;
  }
}

.k-calendar .k-calendar-view .k-today {
  color: $secondary-blue;
}
.k-timeselector .k-dialog-content .k-item:hover {
  color: $secondary-blue;
}

input.k-textbox:disabled {
  background-color: $grey-lighter;
}

.search-fields {
  display: flex;
  flex-flow: row wrap;
  align-items: normal;

  &-container {
    @extend .search-fields;
    padding-right: 25px;
    flex-wrap: nowrap !important;
    &-buttons {
      &-true {
        padding-top: 20px;
      }
      &-false {
        width: 100%;
        padding-top: 20px;
      }
    }
    &-width {
      @extend .search-fields-container;
      gap: 15px;
      &-1 {
        @extend .search-fields-container-width;
        flex: 0 1 100%;
      }
      &-2 {
        @extend .search-fields-container-width;
        flex: 0 1 50%;
      }
      &-3 {
        @extend .search-fields-container-width;
        flex: 0 1 33%;
      }
      &-4 {
        @extend .search-fields-container-width;
        flex: 0 1 25%;
      }
    }
  }
}

.header-back-button {
  text-align: right;
  margin: -5.5em 0em 0 0em !important;
}

.flex-child {
  flex: 1;
}

.custom-label {
  width: 50%;
  margin-bottom: 1em;
}

kendo-dropdownlist {
  background-color: $white !important;
  border: 1px solid $grey-darker !important;
  height: 2.5em !important;
}

btn-with-tool-tip:focus-visible,
label:focus-visible {
  outline: inherit !important;
}

.bring-to-front {
  z-index: 20000;
}

.k-svg-i-hyperlink-open{
  pointer-events: auto;
  color: $secondary-blue;
}
.k-i-hyperlink-disabled {
  pointer-events: none !important;
  color: #dfdfdf !important;
}

label.k-form-field{
  .k-button.k-disabled{
    opacity: 0.3;
  }
}

kendo-svgicon {
  svg {
    vertical-align: baseline !important;
  }
}

.k-command-cell{
  svg{
    color: $secondary-blue;
  }
}

.custom-disabled-multiselect .k-svg-icon{
  display: none !important;
}


.custom-multiselect.k-multiselect.custom-disabled-multiselect{
  min-width: 100% !important;
  width: 100% !important;
  min-height: 4rem !important;
  overflow-y: hidden !important;
  scrollbar-width: 0px !important;
}